import React, { useContext, useEffect, useState } from "react";
import Filter from "../components/common/Filter";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import ProductCard from "../components/common/ProductCard";
import Reveal from "../components/motion/Reveal";
import { httpReauest } from "../utils/httprequest";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../components/common/Loading";
import { FilterContext } from "../App";
import { Helmet } from "react-helmet";
import RoutesContent from "../components/layout/RoutesContent";

const Products = () => {
  const [data, setData] = useState();
  const [categorys, setcategorys] = useState();
  const [loading, setloading] = useState(false);
  const [selected, setselected] = useState(false);
  const [show, setshow] = useState(false);
  const { category } = useParams();

  const { filter_category, setfilter_category } = useContext(FilterContext);

  async function getData(ca) {
    setloading(true);
    const newData = await httpReauest(
      "GET",
      `/product${ca ? `?category=${ca._id}` : ""}`,
      {},
      {}
    );

    setData(newData.data.data);
    setloading(false);
  }

  async function getCategory() {
    const cate = await httpReauest("GET", "/product/category", {}, {});

    setcategorys(cate.data.data);

    if (category) {
      const finded = cate.data.data?.find((e) => e.url === category);
      getData(finded);
    } else {
      getData();
    }
  }

  useEffect(() => {
    getCategory();
  }, [category]);

  useEffect(() => {
    getData();
  }, [filter_category]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"سیریر | محصولات"}</title>
        <meta
          name="description"
          content={
            "سیریر، بستری برای ارتقاء توانمندی‌ها و پیشرفت شخصی و حرفه‌ای"
          }
        />
        <meta
          name="og:description"
          content={
            "سیریر، بستری برای ارتقاء توانمندی‌ها و پیشرفت شخصی و حرفه‌ای"
          }
        />
        <meta name="og:title" content={"سیریر | محصولات"} />
      </Helmet>
      <Header />
      <div style={{ backgroundColor: "#eee" }} class="app-content">
        <div class="u-s-p-y-90">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-12">
                <div class="shop-p">
                  <div class="shop-p__toolbar u-s-m-b-30 px-2">
                    <Reveal>
                      <div class="shop-p__meta-wrap u-s-m-b-30 bg-white">
                        <span class="shop-p__meta-text-1">
                          {data?.length} محصول پیدا شد
                        </span>
                      </div>
                    </Reveal>
                    <Reveal>
                      <div class="shop-p__tool-style px-2 flex-wrap">
                        <h1
                          onClick={() => setshow(true)}
                          class="shop-w-master__heading u-s-m-b-15 lg:hidden"
                        >
                          <i class="fa-solid fa-filter u-s-m-l-8"></i>

                          <span>فیلتر </span>
                        </h1>
                      </div>
                    </Reveal>
                  </div>
                  <div class="shop-p__collection">
                    <div class="row is-grid-active">
                      {!loading ? (
                        data?.map((e) => {
                          return <ProductCard data={e} />;
                        })
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </div>
                  {/* <div class="u-s-p-y-60">
                    <ul class="shop-p__pagination">
                      <li class="is-active">
                        <a href="shop-grid-right.html">1</a>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">2</a>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">3</a>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">4</a>
                      </li>
                      <li>
                        <a
                          class="fa-solid fa-angle-left"
                          href="shop-grid-right.html"
                        ></a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <Filter
                categorys={categorys}
                setselected={setselected}
                show={show}
                setshow={setshow}
              />
            </div>
          </div>
        </div>
        <RoutesContent />
      </div>
      <Footer />
    </div>
  );
};

export default Products;
