import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ProductSlider from "../components/common/ProductSlider";
import Reveal from "../components/motion/Reveal";
import ProductCard from "../components/common/ProductCard";
import { httpReauest } from "../utils/httprequest";
import { Link, useParams } from "react-router-dom";
import RateToStar from "../utils/rateToStart";
import { toast } from "react-toastify";
import { BASE_URL, CDN_BASE_URL } from "../config";
import Countdown from "react-countdown";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FaPlus, FaTimes } from "react-icons/fa";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import StartNow from "../components/home/StartNow";
import FAQ from "../components/home/FAQ";
import { Helmet } from "react-helmet";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import CustomModal from "../components/common/Modal";
import CustomModalImage from "../components/common/ModalImage";
import { Autoplay } from "swiper/modules";

const EventDetails = () => {
  const [selectedImage, setselectedImage] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [completed, setcompleted] = useState();
  const [user, setuser] = useState();
  const [show, setshow] = useState();
  const [error, seterror] = useState();
  const [loading, setloading] = useState();
  const { id } = useParams();
  const [img, setImg] = useState();
  const [previewImg, setPreviewImg] = useState();

  const [data, setData] = useState();
  const [data2, setData2] = useState();
  useEffect(() => {
    getData();
  }, [id]);

  async function getData() {
    const newData = await httpReauest("GET", "/event/" + id, {}, {});

    setData(newData.data.data);

    const newData2 = await httpReauest("GET", "/event", {}, {});

    setData2(newData2.data.data);

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setIsLogin(true);
      getUser(user._id, user.token);
    }
  }

  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      setcompleted(true);
      return (
        <div className="px-3 flex justify-center items-center border-2  p-2 rounded whitespace-nowrap text-[14px] lg:text-[18px] w-full">
          زمان ثبت نام تمام شد
        </div>
      );
    } else {
      return (
        <div className=" flex  text-black gap-3 justify-center ">
          <span className="px-3  flex flex-col justify-center items-center border border-black  w-[58px] h-[58px] rounded-[16px]">
            <h3 className="m-0 text-[16px] leading-5">
              {seconds < 10 ? "0" + seconds : seconds}
            </h3>
            <h6 className="text-[14px]">ثانیه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center border border-black  w-[58px] h-[58px] rounded-[16px]">
            <h3 className="m-0 text-[16px] leading-5">
              {minutes < 10 ? "0" + minutes : minutes}
            </h3>
            <h6 className="text-[14px]">دقیقه</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center border border-black  w-[58px] h-[58px] rounded-[16px]">
            <h3 className="m-0 text-[16px] leading-5">
              {hours < 10 ? "0" + hours : hours}
            </h3>
            <h6 className="text-[14px]">ساعت</h6>
          </span>
          <span className="px-3  flex flex-col justify-center items-center border border-black  w-[58px] h-[58px] rounded-[16px]">
            <h3 className="m-0 text-[16px] leading-5">
              {days < 10 ? "0" + days : days}
            </h3>
            <h6 className="text-[14px]">روز</h6>
          </span>
        </div>
      );
    }
  };

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      phone: Yup.string().required("الزامی است"),
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      nationalCode: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      if (!img) {
        return toast.error("عکس فیش را بارگزاری کنید");
      }
      setloading(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("phone", values.phone);
      formdata.append("nationalCode", values.nationalCode);
      formdata.append("event", id);
      if (img) {
        formdata.append("payment", img, img?.name);
      }

      await fetch(BASE_URL + "/event/sign", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user?.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success(
            "اطلاعات شما ثبت شد برای اطلاعات بیشتز همکاران ما با ما شما ارتباط برقرار میکنند"
          );
          formik.resetForm();
          setloading(false);
          setshow(false);
        }
      });
    },
  });

  async function getUser(_id, token) {
    await httpReauest(
      "GET",
      "/user/" + _id,
      {},
      { "x-access-token": token }
    ).then((res) => {
      setuser(res.data.data);
      formik.setFieldValue(
        "name",
        `${res.data.data.f_name} ${res.data.data.l_name}`
      );
      formik.setFieldValue("phone", res.data.data.phone);
    });
  }

  // function isValidIranianNationalCode(input) {
  //   if (
  //     input ==
  //     ("1111111111" ||
  //       "2222222222" ||
  //       "3333333333" ||
  //       "4444444444" ||
  //       "5555555555" ||
  //       "6666666666" ||
  //       "7777777777" ||
  //       "8888888888" ||
  //       "9999999999" ||
  //       "0000000000")
  //   ) {
  //     formik.setFieldError("nationalCode", "کد ملی صحیح نمی باشد");
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  function nationalCodeHandle(e) {
    const reg = /^(\d)\1*$/;

    if (e.length !== 10) {
      seterror("معتبر نیست");
    } else {
      if (
        e ==
        ("1111111111" ||
          "2222222222" ||
          "3333333333" ||
          "4444444444" ||
          "5555555555" ||
          "6666666666" ||
          "7777777777" ||
          "8888888888" ||
          "9999999999" ||
          "0000000000")
      ) {
        return seterror("معتبر نیست");
      }
      seterror(null);
    }
  }

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.name}</title>
        <meta name="description" content={data?.desc} />
        <meta name="og:description" content={data?.desc} />
        <meta name="og:title" content={data?.name} />
      </Helmet>
      <Header />
      <CustomModalImage
        show={selectedImage}
        onHide={() => setselectedImage(false)}
        fit
      >
        <img
          className="h-full object-contain"
          src={CDN_BASE_URL + selectedImage}
        />
      </CustomModalImage>
      <div class="app-content pb-8">
        <div class="u-s-p-t-60 relative">
          {show && (
            <div
              style={{ backdropFilter: "blur(100px)" }}
              className="absolute  left-0 top-0 lg:w-full h-full z-30 bg-[#fafafa]/70 pt-10 flex items-start lg:items-center justify-center"
            >
              <form
                onSubmit={formik.handleSubmit}
                className="w-full lg:w-[850px] flex flex-wrap px-6 justify-between"
              >
                {" "}
                <div className=" col-lg-12 w-full">
                  <FaTimes
                    onClick={() => setshow(false)}
                    className="text-[#1B1F37] text-[24px] lg:text-[26px] lg:mb-5 cursor-pointer"
                  />
                </div>
                <div className="col-lg-7">
                  <span className="p-0 col-12 mt-3 grid lg:grid-cols-5 whitespace-nowrap items-center">
                    <label className="text-[#1B1F37] text-[14px] lg:text-[16px] lg:col-span-2">
                      نام و نام خانوادگی *
                    </label>
                    <input
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full mt-2 border border-[#515890] p-2 rounded-[4px] bg-transparent lg:col-span-3 text-[#1b1f37]"
                    />
                    {formik.errors.name && formik.touched.name && (
                      <small className="text-red-500">
                        {formik.errors.name}
                      </small>
                    )}
                  </span>

                  <span className="p-0 col-12 mt-3 grid lg:grid-cols-5 whitespace-nowrap items-center">
                    <label className="text-[#1B1F37] text-[14px] lg:text-[16px] lg:col-span-2">
                      کد ملی *
                    </label>

                    <input
                      id="nationalCode"
                      name="nationalCode"
                      value={formik.values.nationalCode}
                      onChange={(e) => {
                        formik.handleChange(e);
                        nationalCodeHandle(e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      className="w-full mt-2 border border-[#515890] p-2 rounded-[4px] bg-transparent lg:col-span-3 text-[#1b1f37]"
                    />
                    {error && <small className="text-red-500">{error}</small>}
                  </span>

                  <span className="p-0 col-12 mt-3 grid lg:grid-cols-5 whitespace-nowrap items-center">
                    <label className="text-[#1B1F37] text-[14px] lg:text-[16px] lg:col-span-2">
                      شماره همراه *
                    </label>
                    <input
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full mt-2 border border-[#515890] p-2 rounded-[4px] bg-transparent lg:col-span-3 text-[#1b1f37]"
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <small className="text-red-500">
                        {formik.errors.phone}
                      </small>
                    )}
                  </span>
                  <div className="lg:hidden flex justify-center flex-wrap mx-auto p-2 bg-[#E5E5E5] mt-5 w-full">
                    <label
                      htmlFor="img"
                      className="relative w-full mt-2 border-dashed border-2 rounded-[2px] p-3 h-[250px] cursor-pointer border-[#A5A5A5] "
                    >
                      {previewImg ? (
                        <img
                          src={previewImg}
                          className="w-full h-full object-contain"
                          alt=""
                        />
                      ) : (
                        <span className="absolute left-1/2 top-1/2 flex flex-col justify-center items-center -translate-x-1/2 -translate-y-1/2 text-[#767676] text-[14px] text-center">
                          <img
                            src="/media and aoudio.png"
                            alt="media and aoudio"
                          />
                          لطفا رسید پرداخت خود را بارگذاری کنید
                        </span>
                      )}
                    </label>
                    <input
                      onChange={(e) => {
                        handleImg(e);
                      }}
                      type="file"
                      className="hidden"
                      id="img"
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>

                  <span className="col-12 mt-5 p-0 flex justify-center">
                    {loading ? (
                      <div
                        id="spinner"
                        className="show  d-flex align-items-center justify-content-center"
                      >
                        <div
                          className="spinner-border text-red-500"
                          style={{ width: "2rem", height: "2rem" }}
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="shop-now-link btn--e-brand w-full"
                      >
                        ثبت نام
                      </button>
                    )}
                  </span>
                </div>
                <div className="col-lg-5">
                  <div className="lg:flex hidden justify-center flex-wrap mx-auto p-2 bg-[#E5E5E5] mt-5 w-full">
                    <label
                      htmlFor="img"
                      className="relative w-full mt-2 border-dashed border-2 rounded-[2px] p-3 h-[250px] cursor-pointer border-[#A5A5A5] "
                    >
                      {previewImg ? (
                        <img
                          src={previewImg}
                          className="w-full h-full object-contain"
                          alt=""
                        />
                      ) : (
                        <span className="absolute left-1/2 top-1/2 flex flex-col justify-center items-center -translate-x-1/2 -translate-y-1/2 text-[#767676] text-[14px] text-center">
                          <img
                            src="/media and aoudio.png"
                            alt="media and aoudio"
                          />
                          لطفا رسید پرداخت خود را بارگذاری کنید
                        </span>
                      )}
                    </label>
                    <input
                      onChange={(e) => {
                        handleImg(e);
                      }}
                      type="file"
                      className="hidden"
                      id="img"
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                </div>
                {/* <span className="col-12 p-3">
                          {success && (
                            <Alert
                              type={"success"}
                              contnet={
                                "اطلاعات شما با موفقیت ثبت شد و مورد بررسی قرار میگیرد"
                              }
                            />
                          )}
                          {errors && <Alert type={"error"} contnet={errors} />}
                        </span> */}
              </form>
            </div>
          )}
          <div class="container px-4">
            <div class="grid lg:grid-cols-12  bg-[#FAFAFA] rounded-[25px] p-0 overflow-hidden">
              <div class="lg:col-span-5 h-full col-span-full">
                <div class="mb-6 lg:mb-0 relative h-full">
                  <span
                    style={{
                      background:
                        "linear-gradient(125.76deg, #0F8C9590 0.97%, #2A3056 100%)",
                    }}
                    className="absolute left-0 top-0 w-full h-full opacity-65"
                  ></span>
                  <span className="absolute lg:bottom-[10%] text-white ps-6 lg:ps-0 text-center border-r-[3px] border-white bottom-6 right-4 lg:right-[8%]">
                    <h2 className="text-[18px] lg:text-[32px] leading-9">
                      {data?.category?.name}
                    </h2>
                    <h1 className="text-[32px] lg:text-[54px] px-2">
                      {data?.name}
                    </h1>
                  </span>
                  <img
                    src={CDN_BASE_URL + data?.img}
                    className="w-full rounded h-full max-h-[650px] object-cover"
                    alt={data?.name}
                  />
                </div>
              </div>
              <div class="lg:col-span-7 col-span-full">
                <div class="pd-detail p-4 lg:p-10">
                  <Reveal width={"fit-content"}>
                    <div>
                      <span class="text-[#292929] text-[18px] lg:text-[32px]">
                        درباره {data?.category?.name}:
                      </span>
                    </div>
                  </Reveal>

                  <div class="u-s-m-y-10">
                    <Reveal width={"fit-content"}>
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.desc }}
                        class="text-[#1B1F37] text-[14px] text-justify"
                      />
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-10 u-s-m-t-20">
                    <Reveal width={"fit-content"}>
                      <span class="text-[#292929] text-[16px] lg:text-[18px] border-r-2 ps-3 border-[#142819]">
                        تاریخ برگزاری :{" "}
                        {moment(data?.date).format("YYYY/MM/DD")}
                      </span>
                    </Reveal>
                  </div>

                  <div class="u-s-m-b-15 border-b-2 pb-5 border-[#D6D6D6]">
                    <Reveal width={"fit-content"}>
                      <span class="text-[#292929] text-[16px] lg:text-[18px] border-r-2 ps-3 border-[#142819]">
                        محل برگزاری : {data?.location}
                      </span>
                    </Reveal>
                  </div>
                  <span className="flex justify-center lg:hidden mb-3">
                    {" "}
                    <FlipClockCountdown
                      dir="ltr"
                      to={data?.date ? new Date(data?.date) : new Date()}
                      showLabels={false}
                      digitBlockStyle={{
                        backgroundColor: "#A91E14",
                        height: "40px",
                        width: "25px",
                        fontSize: "18px",
                      }}
                    >
                      <div className="px-3 flex justify-center items-center border-2  p-2 rounded whitespace-nowrap text-[14px] lg:text-[18px] w-full">
                        زمان ثبت نام تمام شد
                      </div>
                    </FlipClockCountdown>
                  </span>
                  {!completed && (
                    <div className="hidden lg:flex justify-between text-[#1B1F37] text-[18px] lg:text-[24px]">
                      <span>هزینه پیش ثبت نام {data?.category?.name} :</span>
                      <span>{data?.price} تومان</span>
                    </div>
                  )}

                  {data?.voice && (
                    <div class="u-s-m-y-25" dir="ltr">
                      <Reveal>
                        <AudioPlayer
                          src={CDN_BASE_URL + data.voice}
                          showSkipControls
                          customAdditionalControls={[]}
                          className="w-full"
                          // other props here
                        />
                      </Reveal>
                    </div>
                  )}
                  <div className="flex flex-col lg:flex-row justify-between text-[#1B1F37] text-[24px] gap-5">
                    {!completed && (
                      <div className="lg:hidden flex justify-between text-[#1B1F37] text-[16px] lg:text-[24px]">
                        <span>هزینه پیش ثبت نام {data?.category?.name} :</span>
                        <span>{data?.price} تومان</span>
                      </div>
                    )}
                    <span className="lg:flex justify-center hidden w-full">
                      {" "}
                      <FlipClockCountdown
                        dir="ltr"
                        to={data?.date ? new Date(data?.date) : new Date()}
                        showLabels={false}
                        digitBlockStyle={{
                          backgroundColor: "#A91E14",
                          height: "40px",
                          width: "35px",
                          fontSize: "20px",
                        }}
                      >
                        <div className="px-3 flex justify-center items-center border-2  p-2 rounded whitespace-nowrap text-[14px] lg:text-[18px] w-full">
                          زمان ثبت نام تمام شد
                        </div>
                      </FlipClockCountdown>
                    </span>
                    {!completed && (
                      <button
                        onClick={() => {
                          window.scroll({ top: 0, behavior: "smooth" });
                          setshow(true);
                        }}
                        className="btn w-full py-4 bg-[--brand] text-white  flex justify-center items-center rounded-[25px] text-[18px]"
                      >
                        ثبت نام اقساطی
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-m-y-60 px-4">
          <div class="container  p-4">
            <div class="row">
              <h2 className="text-[24px] lg:text-[40px] mb-5 lg:mb-8 text-[#292929]">
                گالری تصاویر
              </h2>
              <div class="w-full lg:px-5">
                {data?.images?.length ? (
                  <Swiper
                    breakpoints={{
                      200: { slidesPerView: 2 },
                      780: { slidesPerView: 3 },
                    }}
                    spaceBetween={40}
                    modules={[Autoplay]}
                    autoplay={{ delay: 3000 }}
                  >
                    {data?.images?.map((item) => (
                      <SwiperSlide>
                        <img
                          onClick={() => setselectedImage(item)}
                          src={CDN_BASE_URL + item}
                          alt={data?.name}
                          className="w-full cursor-pointer"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  "تصویری وجود ندارد"
                )}
              </div>
            </div>
          </div>
        </div>
        <StartNow />
        <FAQ />
      </div>
      <Footer />
    </div>
  );
};

export default EventDetails;
