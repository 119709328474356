import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { httpReauest } from "../../utils/httprequest";
import Accordion from "../common/Accordion";
import AccordionRoutes from "../common/AccordionRoutes";
import { Helmet } from "react-helmet";

const RoutesContent = () => {
  const location = useLocation();
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, [location]);

  async function getData() {
    await httpReauest(
      "GET",
      `/routes/url?route=${location.pathname}`,
      {},
      {}
    ).then((res) => setData(res.data.data));
  }
  return (
    <div className="py-4 lg:py-8">
      <Helmet>
        {" "}
        <title>{data?.meta_title}</title>
        <meta name="description" content={data?.meta_desc} />
        <meta name="og:description" content={data?.meta_desc} />
        <meta name="og:title" content={data?.meta_title} />
      </Helmet>
      <div className="container">
        <AccordionRoutes question={"بیشتر"} value={data?.content} />
      </div>
    </div>
  );
};

export default RoutesContent;
