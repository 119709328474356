import React from "react";
import { SrviceInfo } from "../../pages/ServiceDetails";
import ServiceItem from "../common/ServiceItem";
import { Link } from "react-router-dom";

const WimehrSection = () => {
  return (
    <div className="">
      <section class="bg-white dark:bg-gray-900 py-8">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 class="mb-6 text-2xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              سکوی تجارت بین الملل WIMEHR
            </h2>
            <p class="mb-6 text-[14px] lg:text-[16px]">
              مقصد نهایی شما برای درک بهتر مسیرهای شغلی و رشد حرفه‌ای در بخش
              توسعه صادرات. در پلتفرم ویمهر، ما به ارائه دانش و ابزارهایی که
              برای رسیدن به موفقیت در حیات حرفه‌ای و شخصی لازم دارید، متعهد
              هستیم.
            </p>
            <p className="text-[14px] lg:text-[16px] mb-6">
              ما با هدف ارائه منابع ارزشمند و مشاوره‌های عملی، به شما کمک
              می‌کنیم تا مسیر شغلی خود را با موفقیت بین‌المللی طی کنید. ما به
              ایجاد یک جامعه پویا از یادگیری، توسعه، و رشد متعهدیم.
            </p>
            <div>
              <Link
                to={"https://wimehr.com"}
                target="_blank"
                className="btn--e-primary py-2 px-6 text-[14px] lg:text-[16px] "
              >
                مشاهده
              </Link>
            </div>
          </div>
          <div class="grid mt-8">
            <Link to={"https://wimehr.com"} target="_blank">
              <img
                class="w-full rounded-lg shadow-lg shadow-white/50"
                src="https://wimehr.com/msg1102901531-64614.jpg"
                alt="office content 1"
              />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WimehrSection;
