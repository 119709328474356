import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import ProductCard from "../common/ProductCard";
import { httpReauest } from "../../utils/httprequest";
import { FaAngleLeft } from "react-icons/fa";
import ProductCardAudio from "../common/ProductCardAudio";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const TopProducts = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const newData = await httpReauest("GET", "/product/top", {}, {});

    setData(newData.data.data);
  }

  console.log(data);
  return (
    <div className="u-s-p-b-60 u-s-p-t-60">
      <div className="section__intro  ">
        <div className="container ">
          <div>
            <Reveal head>
              <div className="w-full text-[#05613B] pb-6">
                <h2 className="text-[32px] lg:text-[40px] text-center relative -top-3">
                  آرشیو کتاب های صوتی
                </h2>
              </div>
            </Reveal>
          </div>
        </div>
      </div>

      <div className="section__content ">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className=" u-s-m-y-10">
                <Swiper
                  breakpoints={{
                    120: { slidesPerView: 2, spaceBetween: 30 },
                    560: { slidesPerView: 3, spaceBetween: 30 },
                    1024: { slidesPerView: 4, spaceBetween: 50 },
                  }}
                  modules={[Autoplay]}
                  autoplay={{ delay: 3000 }}
                  loop
                >
                  {data?.map((e) => {
                    return (
                      <SwiperSlide>
                        <ProductCardAudio data={e} home full />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <Link
              to={"/products"}
              className="btn--e-primary py-2 px-6 text-[18px]"
            >
              بیشتر
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopProducts;
