import React from "react";
import { SrviceInfo } from "../../pages/ServiceDetails";
import ServiceItem from "../common/ServiceItem";

const HelpYou = () => {
  return (
    <div className="u-s-p-y-40">
      <div className="section__content">
        <div className="container">
          <div className="row flex-col-reverse lg:flex-row lg:justify-between items-center">
            <div className="grid gap-3 px-2 w-full">
              {SrviceInfo.map((service, index) => (
                <ServiceItem key={service.id} service={service} index={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpYou;