import "./App.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/404";
import Signup from "./pages/Signup";
import ColorSwitcher from "./components/layout/ColorSwitcher";
import Signin from "./pages/Signin";
import Products from "./pages/Products";
import "./sass/main.scss";
import "./sass/utility.css";
import "./sass/vendor.css";
import ProductDetails from "./pages/ProductDetails";
import ServiceDetails from "./pages/ServiceDetails";
import ScrollToTop from "./utils/ScrooltoTop";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import { createContext, useEffect, useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import Checkout from "./pages/Checkout";
import OrderDetails from "./pages/OrderDetails";
import DashPlanner from "./pages/DashPlanner";
import DashboardPremium from "./pages/DashboardPremium";
import "swiper/css";
import "swiper/css/effect-fade";
import EventPage from "./pages/EventPage";
import EventDetails from "./pages/EventDetails";
import TeamInfo from "./pages/TeamInfo";
import Contact from "./pages/Contact";
import PortfolioPage from "./pages/PortfolioPage";
import Lenis from "@studio-freight/lenis";

const lenis = new Lenis({
  autoRaf: true,
});

// تابع رندر برای به‌روزرسانی Lenis
function raf(time) {
  lenis.raf(time); // به‌روزرسانی Lenis
  requestAnimationFrame(raf); // فراخوانی تابع در فریم بعدی
}

// شروع رندر
requestAnimationFrame(raf);

export const FilterContext = createContext();

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <ScrollToTop />
          <Home />
        </>
      ),
    },
    {
      path: "/signup",
      element: (
        <>
          <ScrollToTop />
          <Signup />
        </>
      ),
    },
    {
      path: "/signin",
      element: (
        <>
          <ScrollToTop />
          <Signin />
        </>
      ),
    },
    {
      path: "/team/:name",
      element: (
        <>
          <ScrollToTop />
          <TeamInfo />
        </>
      ),
    },
    {
      path: "/portfolio/:id",
      element: (
        <>
          <ScrollToTop />
          <PortfolioPage />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <>
          <ScrollToTop />
          <Dashboard />
        </>
      ),
    },
    {
      path: "/dashboard/premium",
      element: (
        <>
          <ScrollToTop />
          <DashboardPremium />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <ScrollToTop />
          <About />
        </>
      ),
    },
    {
      path: "/contact",
      element: (
        <>
          <ScrollToTop />
          <Contact />
        </>
      ),
    },
    {
      path: "/service/:id",
      element: (
        <>
          <ScrollToTop />
          <ServiceDetails />
        </>
      ),
    },
    {
      path: "/order/:id",
      element: (
        <>
          <ScrollToTop />
          <OrderDetails />
        </>
      ),
    },

    {
      path: "/event",
      element: (
        <>
          <ScrollToTop />
          <EventPage />
        </>
      ),
    },
    {
      path: "/products",
      element: (
        <>
          <ScrollToTop />
          <Products />
        </>
      ),
    },
    {
      path: "/products/:category",
      element: (
        <>
          <ScrollToTop />
          <Products />
        </>
      ),
    },
    {
      path: "/products/:category/:id",
      element: (
        <>
          <ScrollToTop />
          <ProductDetails />
        </>
      ),
    },
    {
      path: "/event/:id",
      element: (
        <>
          <ScrollToTop />
          <EventDetails />
        </>
      ),
    },
    {
      path: "*",
      element: (
        <>
          <ScrollToTop />
          <NotFound />
        </>
      ),
    },
  ]);

  const [cookies, setCookie] = useCookies(["cart"]);

  const [filter_category, setfilter_category] = useState();

  return (
    <>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <FilterContext.Provider value={{ filter_category, setfilter_category }}>
          {/* <ColorSwitcher /> */}
          <RouterProvider router={router} />
        </FilterContext.Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
