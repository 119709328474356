import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const Accordion = ({ question, value }) => {
  const [show, setShow] = useState(false);
  return (
    <div class="bg-[#FBF3E5] rounded-[8px] px-3 lg:px-7 py-4 lg:py-5 relative">
      <img src="/rounded.png" className="absolute -right-2 -top-2 w-24" />
      <img src="/rounded.png" className="absolute -left-2 -bottom-2 rotate-180 w-24" />
      <button
        onClick={() => setShow(!show)}
        class="w-full flex gap-1 justify-between items-center  text-[#292929] text-[16px] lg:text-[22px]"
      >
        <span className="text-right">{question}</span>
        <span
          id="icon-1"
          class={`${
            show ? "rotate-180" : ""
          }  transition-transform duration-300`}
        >
          <FaAngleDown />
        </span>
      </button>
      <div
        id="content-1"
        class={`${
          show ? "max-h-96" : "max-h-0"
        } overflow-hidden transition-all duration-300 ease-in-out font-[Shabnam-thin]`}
      >
        <div class="text-[14px] lg:text-[18px] text-[#292929] pt-6">
          {value}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
