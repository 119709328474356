import React from "react";
import { motion, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";

const ServiceItem = ({ service, index }) => {
  const ref = React.useRef(null);
  const refImg = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const ImgInView = useInView(refImg, { once: false, margin: "-100px" });

  return (
    <motion.div
      ref={ref}
      initial={{ scale: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      className="w-full p-2 rounded-[25px] duration-300 hover:shadow-lg hover:bg-[#fff]"
    >
      <Reveal>
        <Link to={"/service/" + service.id}>
          <div className="grid lg:grid-cols-5 items-center w-full gap-2 lg:gap-20 py-3 lg:py-6">
            <motion.span
              initial={{ x: -50, opacity: 0 }}
              animate={isInView ? { x: 0, opacity: 1 } : {}}
              transition={{ duration: 0.5, delay: 0.4 }}
              style={{
                background:
                  index % 2
                    ? "linear-gradient(125.76deg, #142819 0.97%, #2A3056 100%)"
                    : "linear-gradient(125.76deg, #67FFF2 0.97%, #2A3056 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              className={`lg:col-span-3 lg:hidden ${
                index % 2 ? "lg:order-2 lg:ps-10" : "lg:order-1 lg:pe-10"
              }`}
            >
              <h3 className="text-[22px] text-center font-bold font-sans">
                {service.title_en}
              </h3>
            </motion.span>
            <span
              className={`w-full rounded-[30px] h-[180px] lg:h-[260px] object-cover lg:col-span-2 overflow-hidden ${
                index % 2 ? "lg:order-1" : "lg:order-2"
              }`}
            >
              <motion.img
                ref={refImg}
                initial={{ scale: 1 }}
                animate={ImgInView ? { scale: 1.1, opacity: 1 } : { scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                alt="Test"
                className="w-full object-cover h-full"
                src={service.img}
              />
            </span>

            <motion.span
              animate={ImgInView ? { scale: 1.05 } : { scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              style={{
                background:
                  index % 2
                    ? "linear-gradient(125.76deg, #142819 0.97%, #2A3056 100%)"
                    : "linear-gradient(125.76deg, #67FFF2 0.97%, #2A3056 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              className={`lg:col-span-3   py-2 lg:py-5 ${
                index % 2
                  ? "lg:order-2 lg:ps-10 lg:pe-7"
                  : "lg:order-1 lg:pe-10 lg:ps-7"
              }`}
            >
              <h3 className="text-[28px] hidden lg:block lg:text-[48px] mb-3 lg:mb-10 text-left font-bold font-sans">
                {service.title_en}
              </h3>
              <h3 className="text-[26px] lg:text-right text-center lg:text-[40px] mb-1 lg:mb-6">
                {service.title}
              </h3>
              <p className="text-[16px] lg:text-right text-center lg:text-[24px]">
                {service.subTitle}
              </p>
            </motion.span>
          </div>
        </Link>
      </Reveal>
    </motion.div>
  );
};

export default ServiceItem;
