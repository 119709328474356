import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import { httpReauest } from "../../utils/httprequest";
import { Swiper, SwiperSlide } from "swiper/react";
import { CDN_BASE_URL } from "../../config";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";

const PartnerLogos = ({ data }) => {
  if (data?.length) {
    return (
      <div className="u-s-p-b-60 u-s-p-t-60 clients2">
        <div className="section__intro  ">
          <div className="container ">
            <div>
              <Reveal head>
                <div className="w-full text-[#05613B] pb-6">
                  <h2 className="text-[32px] lg:text-[40px] text-center relative -top-3">
                    همکاری ها
                  </h2>
                </div>
              </Reveal>
            </div>
          </div>
        </div>

        <div className="section__content clients2">
          <div className="container">
            <Swiper
              breakpoints={{
                100: { slidesPerView: 2 },
                600: { slidesPerView: 3 },
                1024: { slidesPerView: 5 },
              }}
              modules={[Autoplay, Pagination]}
              autoplay={{
                delay: 1000,
                
              }}
              loop
              pagination={{
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
              }}
            >
              {data?.map((item) => (
                <SwiperSlide>
                  <div className=" flex items-center justify-center  p-2 lg:p-4 h-[90px] lg:h-[160px]">
                    <img
                      src={CDN_BASE_URL + item.logo}
                      alt={"لوکو" + item?.name}
                      className="mx-auto object-contain  h-full w-full"
                    />
                  </div>
                </SwiperSlide>
              ))}
              <div class="swiper-pagination"></div>
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
};

export default PartnerLogos;
