import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Reveal from "../motion/Reveal";

const Reviews = () => {
  return (
    <div style={{ background: "#eee" }} class="u-s-p-y-80 ">
      <div class="section__intro u-s-m-b-46">
        <div class="container">
          <div class="row row--center">
            <Reveal>
              <div class="col-lg-12">
                <div class="section__text-wrap">
                  <h1 class="text-[32px] lg:text-[40px] text-[#292929] u-s-m-b-12">
                    گزیده ای از پیام همراهان
                  </h1>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </div>

      <div class="section__content">
        <div class="container">
          <div class="">
            <div class="" id="testimonial-slider">
              <Swiper
                spaceBetween={40}
                loop
                autoplay={{ delay: 4000 }}
                modules={[Autoplay]}
                breakpoints={{
                  10: { slidesPerView: 1 },
                  720: { slidesPerView: 2 },
                }}
                centeredSlides={true}
              >
                {Array(5)
                  .fill("")
                  .map(() => (
                    <SwiperSlide>
                      <Reveal>
                        <div class="bg-[#2A3056] p-4 rounded-[16px] text-white">
                          <div class="flex border-b pb-3 items-center gap-4 border-[#515890]">
                            <img
                              class="w-[60px] h-[60px] rounded-full"
                              src="images/about/test-1.jpg"
                              alt=""
                            />
                            <h5 class="text-[20px] font-[500]">نام کاربر</h5>
                          </div>
                          <p className="py-4">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                            آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا
                            با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            علی الخ
                          </p>
                        </div>
                      </Reveal>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
