import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { motion } from "framer-motion"; // Import framer-motion
import Reveal from "../motion/Reveal";
import { FaAngleLeft } from "react-icons/fa6";

const Slider = () => {
  return (
    <div className="">
      <div
        style={{ backgroundImage: "url(Frame.png)" }}
        className="flex-col flex justify-center items-center pb-4 bg-contain relative"
      >
        <Reveal head>
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-[#0A0A0A] text-[22px] lg:text-[32px] text-center mt-3"
          >
            شبکه هوش برتر
          </motion.h2>
        </Reveal>
        <Reveal head>
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-[#0A0A0A] text-[34px] lg:text-[45px] text-center mt-2"
          >
            'سیریر'
          </motion.h1>
        </Reveal>
        <Reveal>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="bg-[#0A0A0A] rounded-full p-2 flex items-center gap-5 text-[12px] lg:text-[18px] text-white mb-2"
          >
            <span>جایی برای شکوفایی استعدادهای انسانی</span>
            <Link
              to={"/contact"}
              className="bg-[#142819] hover:scale-105 duration-200 rounded-full p-3 text-white text-[14px] items-center gap-2 px-4 hidden lg:flex"
            >
              عضو شبکه هوش برتر شو
              <FaAngleLeft />
            </Link>
          </motion.div>
        </Reveal>
        <Reveal>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <Link
              to={"/contact"}
              className="bg-[--primary]  rounded-full p-2 text-white text-[14px] items-center gap-2 px-4 flex lg:hidden"
            >
              عضو شبکه هوش برتر شو
              <FaAngleLeft />
            </Link>
          </motion.div>
        </Reveal>
      </div>
      <div className="lg:h-[90vh] relative py-6 px-4  lg:px-12 xl:px-20 2xl:px-32">
        <div className="relative lg:static ">
          <motion.video
            initial={{ opacity: 0.5, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1.2, delay: 0.3 }}
            autoPlay
            playsInline
            loop
            className="lg:absolute left-0 top-0 w-full h-full object-cover rounded-[16px] lg:rounded-none shadow"
            src="/video/landing.mp4"
            alt="Slider Video"
            muted
            preload="auto"
          />
          <span
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 13.53%, #000000 95.42%)",
            }}
            className="w-full h-full absolute left-0 top-0 z-10 rounded-[16px] lg:rounded-none"
          ></span>
          <div className="absolute top-[55%] md:top-[45%] translate-y-[-50%] right-[20px] lg:right-[8%] w-full z-20   text-white">
            <Swiper modules={[Autoplay]} autoplay={{ delay: 5000 }} loop>
              <SwiperSlide>
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1, delay: 2 }}
                  className=" grid pe-10 md:grid-cols-2 "
                >
                  <span>
                    <h2 className="font-bold text-[18px] md:text-[30px]  lg:text-[45px] pe-5">
                      مجموعه کتاب های صوتی برای پیشرفت
                    </h2>
                    <Link
                      to={"/signin"}
                      className="w-fit  px-4 lg:px-6 py-1 lg:py-2 rounded-lg lg:text-[16px] text-[12px] btn--e-brand flex justify-center items-center mt-3 lg:mt-5"
                    >
                      مشترک شو
                    </Link>
                  </span>
                </motion.div>
              </SwiperSlide>
              <SwiperSlide>
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1, delay: 2.5 }}
                  className=" grid pe-10 md:grid-cols-2 "
                >
                  <span>
                    <h2 className="font-bold text-[18px] md:text-[30px]  lg:text-[45px] pe-5">
                      بستری برای ارتقــــــــــــاء توانمندی ها و پیشرفت
                      شخصـــــــی و حرفــــــــه ای
                    </h2>
                    <Link
                      to={"/signin"}
                      className="w-fit hover:scale-105 duration-200 px-4 lg:px-6 py-1 lg:py-2 rounded-lg lg:text-[16px] text-[12px] btn--e-brand flex justify-center items-center mt-3 lg:mt-5"
                    >
                      پیشرفت رو با ما شروع کن
                    </Link>
                  </span>
                </motion.div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
