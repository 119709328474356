import { useEffect, useState } from "react";
import {
  FaComment,
  FaEnvelope,
  FaPhone,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import Reveal from "../components/motion/Reveal";
import { FaTelegramPlane } from "react-icons/fa";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const [show, setShow] = useState();
  const [show2, setShow2] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY === 0) {
        setShow2(false);
      }
      if (window.scrollY > 0) {
        setShow2(true);
      }
    });
  }, []);

  return (
    <>
      <Reveal head delay={0.5}>
        {show2 && (
          <div
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onTouchStart={() => {
              setShow(true);
              setTimeout(() => {
                setShow(false);
              }, 5000);
            }}
            dir="ltr"
            className=" fixed cursor-pointer text-white bottom-2 lg:left-6 left-2 lg:bottom-6 z-40 duration-200"
          >
            <div
              className={`${
                show
                  ? "opacity-100 translate-x-0"
                  : "opacity-0 -translate-x-full"
              } flex flex-col gap-4 items-center duration-200`}
            >
              <Link
                to={"https://wa.me/+989111531708"}
                className="flex flex-col items-center justify-center gap-1 text-slate-800 text-[14px] duration-200"
              >
                <span className="border bg-green-500 p-3 rounded-full">
                  <FaWhatsapp className="text-white text-[26px] lg:text-[30px]" />
                </span>
              </Link>

              <Link
                to={"https://t.me/Mahyarbbz"}
                className="flex flex-col items-center justify-center gap-1 text-slate-800 text-[14px] duration-200"
              >
                <span className="border bg-blue-400 p-3 rounded-full">
                  <FaTelegramPlane className="text-white text-[26px] lg:text-[30px]" />
                </span>
              </Link>

              <Link
                to={"mailto:wimehr@gmail.com"}
                className="flex flex-col items-center justify-center gap-1 text-slate-800 text-[14px] duration-200"
              >
                <span className="border bg-yellow-500 p-3 rounded-full">
                  <FaEnvelope className="text-white text-[26px] lg:text-[30px]" />
                </span>
              </Link>

              <Link
                to={"tel:+989111531708"}
                className="flex flex-col items-center justify-center gap-1 text-slate-800 text-[14px] duration-200"
              >
                <span className="border bg-green-700 p-3 rounded-full">
                  <FaPhone className="text-white text-[26px] lg:text-[30px]" />
                </span>
              </Link>
            </div>

            <div
              className={`${
                !show ? "opacity-100" : "opacity-0 h-0"
              } bg-green-500  p-3 lg:p-4 rounded-full duration-200 relative w-fit`}
            >
              <span className="absolute bottom-[110%] left-0">
                <Reveal head delay={1}>
                  <span className=" bg-slate-800 whitespace-nowrap px-2 py-1 rounded text-[12px] lg:text-[14px]">
                    همین حالا ارتباط بگیر
                  </span>
                </Reveal>
              </span>

              <FaPhone className="icon_phone text-[26px] lg:text-[30px] " />
            </div>
          </div>
        )}
      </Reveal>
    </>
  );
}
