import React from "react";
import Reveal from "../motion/Reveal";
import CountUp from "react-countup";

const HomeCounter = () => {
  return (
    <div className="bg-[#fafafa]  lg:py-24 py-16 ">
      <Reveal head>
        <div className="gap-2 lg:gap-4 grid grid-cols-2 lg:grid-cols-4 justify-center container">
          <span
            style={{ boxShadow: "0px 4px 8px 0px rgba(217, 217, 217, 0.2)" }}
            className="bg-white rounded-[30px] p-4 lg:p-6 flex flex-col items-center justify-center"
          >
            <div className="grid grid-cols-2 items-center">
              <span>
                <img src="/website.gif.gif" className="w-full p-1" alt="" />
              </span>
              <span>
                <h5
                  style={{ textShadow: "1px 1px #0000030" }}
                  className="font-bold text-[#02F2DE] text-[34px] lg:text-[65px]"
                >
                  <CountUp
                    scrollSpyOnce
                    enableScrollSpy
                    scrollSpyDelay={700}
                    end={100}
                    duration={3}
                  />
                  +
                </h5>
              </span>
            </div>
            <span className="text-[16px] lg:text-[24px] text-black">وبسایت موفق</span>
          </span>

          <span
            style={{ boxShadow: "0px 4px 8px 0px rgba(217, 217, 217, 0.2)" }}
            className="bg-white rounded-[30px] p-4 lg:p-6 flex flex-col items-center justify-center"
          >
            <div className="grid grid-cols-2 items-center">
              <span>
                <img src="/14251532.gif.gif" className="w-full p-1" alt="" />
              </span>
              <span>
                <h5
                  style={{ textShadow: "1px 1px #0000030" }}
                  className="font-bold text-[#02F2DE] text-[34px] lg:text-[65px]"
                >
                  <CountUp
                    scrollSpyOnce
                    enableScrollSpy
                    scrollSpyDelay={700}
                    end={10}
                    duration={3}
                  />
                  +
                </h5>
              </span>
            </div>
            <span className="text-[16px] lg:text-[24px] text-black">دوره تکمیل شده</span>
          </span>

          <span
            style={{ boxShadow: "0px 4px 8px 0px rgba(217, 217, 217, 0.2)" }}
            className="bg-white rounded-[30px] p-4 lg:p-6 flex flex-col items-center justify-center"
          >
            <div className="grid grid-cols-2 items-center">
              <span>
                <img src="/cooperation.gif.gif" className="w-full p-1" alt="" />
              </span>
              <span>
                <h5
                  style={{ textShadow: "1px 1px #0000030" }}
                  className="font-bold text-[#02F2DE] text-[34px] lg:text-[65px]"
                >
                  <CountUp
                    scrollSpyOnce
                    enableScrollSpy
                    scrollSpyDelay={700}
                    end={100}
                    duration={3}
                  />
                  +
                </h5>
              </span>
            </div>
            <span className="text-[16px] lg:text-[24px] text-black">همکاری موفق</span>
          </span>

          <span
            style={{ boxShadow: "0px 4px 8px 0px rgba(217, 217, 217, 0.2)" }}
            className="bg-white rounded-[30px] p-4 lg:p-6 flex flex-col items-center justify-center"
          >
            <div className="grid grid-cols-2 items-center">
              <span>
                <img src="/audio-book.gif.gif" className="w-full p-1" alt="" />
              </span>
              <span>
                <h5
                  style={{ textShadow: "1px 1px #0000030" }}
                  className="font-bold text-[#02F2DE] text-[34px] lg:text-[65px]"
                >
                  <CountUp
                    scrollSpyOnce
                    enableScrollSpy
                    scrollSpyDelay={700}
                    end={20}
                    duration={3}
                  />
                  +
                </h5>
              </span>
            </div>
            <span className="text-[16px] lg:text-[24px] text-black">کتاب صوتی</span>
          </span>
        </div>
      </Reveal>
    </div>
  );
};

export default HomeCounter;
