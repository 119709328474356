import React, { useEffect, useRef, useState } from "react";
import Reveal from "../motion/Reveal";
import { httpReauest } from "../../utils/httprequest";
import Loading from "../common/Loading";
import Countdown from "react-countdown";
import CustomModal from "../common/Modal";
import SignupForm from "../common/SignupForm";
import { FaLock, FaRegUser, FaTimes } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import TopProducts from "./TopProducts";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../common/ProductCard";
import { Autoplay } from "swiper/modules";
import { CDN_BASE_URL } from "../../config";
import { toast } from "react-toastify";
import { Flipper, Flipped } from "react-flip-toolkit";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

const VITE_SITE_KEY = "6LchGzQqAAAAAACh11TFb0yF1BUb3nQAZMNZUPJD";
const SITE_SECRET = "6LchGzQqAAAAAM1RpZ-oQwac9Bas5x6L19KvPyHL";

const Dontmiss = () => {
  const [hour, sethours] = useState();
  const [show, setshow] = useState();
  const [data, setdata] = useState();
  const [products, setproducts] = useState();
  const [loading, setLoading] = useState();
  const [phone, setphone] = useState("");
  const [step, setstep] = useState();
  const [check, setcheck] = useState();
  const [code, setcode] = useState();
  const [completed, setcompleted] = useState(false);
  const recaptcha = useRef(null);

  async function getData(params) {
    setLoading(true);
    await httpReauest("GET", "/admin/gift", {}, {}).then(({ data }) => {
      sethours(data?.data?.date);
      setdata(data?.data);
    });
    const newData = await httpReauest("GET", "/product", {}, {});

    setproducts(newData.data.data);
    setLoading(false);
  }

  async function postUser() {
    if (phone?.length !== 11) {
      return toast.error("فرمت شماره تماس صحیح نیست");
    }
    await httpReauest("POST", "/admin/gift/user", { phone }, {}).then(
      ({ data }) => {
        setstep(true);
      }
    );
  }

  async function acceptUser(params) {
    await httpReauest(
      "POST",
      "/admin/gift/user/accept",
      { phone, code },
      {}
    ).then(() => {
      window.location.assign(CDN_BASE_URL + data?.file);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   console.log(recaptcha?.current);
  //
  // }, [recaptcha.current]);

  return (
    <div>
      <div class="py-16">
        <div class="container">
          {loading ? (
            <Loading />
          ) : (
            <Reveal head>
              <div class=" w-full">
                <div className="grid lg:grid-cols-2  w-full items-center">
                  <span className="order-[2] lg:order-[1]">
                    <div class="  flex flex-col items-center justify-center">
                      <div class="text-[32px] lg:text-[48px] text-[#1B1F37]">
                        <span class=" ">هدیه ویژه منتظرته!</span>
                      </div>

                      {completed ? (
                        <></>
                      ) : (
                        <>
                          <div class="text-[16px] lg:text-[24px] text-[#1B1F37] font-[Shabnam-thin]">
                            <span>دوره رایگان {data?.name}</span>
                          </div>
                          {step ? (
                            <div className="flex relative font-[Shabnam-thin] w-full mt-5 lg:mt-10 max-w-[420px]">
                              <input
                                className="w-full h-[52px] px-12 placeholder:font-thin font-thin rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343]"
                                placeholder="کد تایید ارسالی را وارد کنید"
                                type="text"
                                id="code"
                                name="code"
                                value={code}
                                onChange={(e) => setcode(e.target.value)}
                              />
                              <FaLock
                                color="#434343"
                                className="absolute right-6 top-1/2 -translate-y-1/2"
                              />
                              <button
                                onClick={acceptUser}
                                className=" btn--e-primary absolute left-1 top-1/2 -translate-y-1/2 h-[90%]  px-5"
                              >
                                ثبت
                              </button>
                            </div>
                          ) : (
                            <div className="flex relative font-[Shabnam-thin] w-full mt-5 lg:mt-10 max-w-[420px]">
                              <input
                                className="w-full h-[52px] border border-[--primary] px-12 placeholder:font-thin font-thin rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343]"
                                placeholder="شماره موبایل"
                                type="text"
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(e) => setphone(e.target.value)}
                              />
                              <FaRegUser
                                color="#434343"
                                className="absolute right-6 top-1/2 -translate-y-1/2"
                              />
                              <button
                                onClick={postUser}
                                disabled={completed}
                                className=" btn--e-primary absolute left-1 top-1/2 -translate-y-1/2 h-[90%] px-5"
                              >
                                ثبت
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </span>
                  <span className="flex flex-col justify-center items-center order-[1] lg:order-[2]">
                    <span className=" ">
                      <img
                        src="/gift.png"
                        className="w-full max-w-[100px] lg:max-w-[200px]"
                        alt="Gift Sirir"
                      />
                    </span>
                    <div dir="ltr" className="flex justify-center my-3">
                      {hour && (
                        <FlipClockCountdown to={new Date(hour)}  digitBlockStyle={{backgroundColor:"#A91E14" }}>
                          <span className="px-3  flex flex-col justify-center items-center bg-[#A91E14]  p-2 rounded mt-4">
                            متاسفانه زمان دریافت هدیه تمام شد
                          </span>
                        </FlipClockCountdown>
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </Reveal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dontmiss;

const FlipCard = ({ value, label }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const elRef = useRef(null);

  useEffect(() => {
    const el = elRef.current;
    const top = el.querySelector(".card__top");
    const bottom = el.querySelector(".card__bottom");
    const back = el.querySelector(".card__back");
    const backBottom = el.querySelector(".card__back .card__bottom");

    const update = (val) => {
      val = ("0" + val).slice(-2);
      if (val !== currentValue) {
        if (currentValue >= 0) {
          back.setAttribute("data-value", currentValue);
          bottom.setAttribute("data-value", currentValue);
        }
        setCurrentValue(val);
        top.innerText = val;
        backBottom.setAttribute("data-value", val);

        el.classList.remove("flip");
        void el.offsetWidth; // Trigger reflow
        el.classList.add("flip");
      }
    };

    update(value);
  }, [value, currentValue]);

  return (
    <div className="flip-clock__piece">
      <span ref={elRef} className="flip-clock__card card">
        <b className="card__top"></b>
        <b className="card__bottom"></b>
        <b className="card__back">
          <b className="card__bottom"></b>
        </b>
      </span>
      <span className="flip-clock__slot">{label}</span>
    </div>
  );
};
