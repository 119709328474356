import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ProductSlider from "../components/common/ProductSlider";
import Reveal from "../components/motion/Reveal";
import ProductCard from "../components/common/ProductCard";
import { httpReauest } from "../utils/httprequest";
import { Link, useParams } from "react-router-dom";
import RateToStar from "../utils/rateToStart";
import { toast } from "react-toastify";
import { BASE_URL, CDN_BASE_URL } from "../config";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaTimes,
  FaVolumeMute,
} from "react-icons/fa";
import CustomModal from "../components/common/Modal";
import {
  FaAngleLeft,
  FaAngleRight,
  FaPause,
  FaPlay,
  FaVolumeHigh,
} from "react-icons/fa6";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";

const ProductDetails = () => {
  const [reviewShow, setReviewShow] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [content, setContent] = useState();
  const [user, setuser] = useState();
  const [rate, setrate] = useState();
  const [show, setshow] = useState();
  const [show2, setshow2] = useState();
  const [img, setImg] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [loading, setloading] = useState();

  const [currentTrack, setTrackIndex] = useState(0);
  const handleClickNext = () => {
    console.log("click next");
    setTrackIndex((currentTrack) =>
      currentTrack < data.files.length - 1 ? currentTrack + 1 : 0
    );
  };

  const handleClickNextNotPremium = () => {
    // toast.warning("برای گوش دادن به قسمت های بعدی باید اشتراک تهیه کنید");
    setshow(true);
  };

  const handleEnd = () => {
    console.log("end");
    setTrackIndex((currentTrack) =>
      currentTrack < data.files.length - 1 ? currentTrack + 1 : 0
    );
  };

  const { id } = useParams();

  const [data, setData] = useState();
  const [data2, setData2] = useState();
  useEffect(() => {
    getData();
  }, [id]);

  async function getUser(_id, token) {
    await httpReauest(
      "GET",
      "/user/" + _id,
      {},
      { "x-access-token": token }
    ).then((res) => {
      setuser(res.data.data);
    });
  }

  async function getData() {
    const newData = await httpReauest("GET", "/product/" + id, {}, {});

    setData(newData.data.data);

    const newData2 = await httpReauest("GET", "/product", {}, {});

    setData2(newData2.data.data);

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setIsLogin(true);
      getUser(user._id, user.token);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));

    if (!rate || !content) {
      toast.error("امتیاز و متن هر دو الزامی است برای ثبت نظر");
    }

    await httpReauest(
      "POST",
      "/product/addReview/" + id,
      { content, rate },
      { "x-access-token": user.token }
    )
      .then((e) => {
        toast.success("نظر شما با موفقیت ثبت شد");
        setContent("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      email: Yup.string().required("الزامی است"),
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      if (!img) {
        return toast.error("عکس فیش را بارگزاری کنید");
      }
      setloading(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("email", values.email);
      formdata.append("product", id);
      if (img) {
        formdata.append("payment", img, img?.name);
      }

      await fetch(BASE_URL + "/product/sign", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user?.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success(
            "اطلاعات شما ثبت شد برای اطلاعات بیشتز همکاران ما با ما شما ارتباط برقرار میکنند"
          );
          formik.resetForm();
          setloading(false);
          setshow2(false);
        }
      });
    },
  });

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.name}</title>
        <meta name="description" content={data?.desc} />
        <meta name="og:description" content={data?.desc} />
        <meta name="og:title" content={data?.name} />
      </Helmet>
      {show2 && (
        <div
          style={{ backdropFilter: "blur(100px)" }}
          className="fixed  left-0 top-0 lg:w-full h-full z-30 bg-[#fafafa]/70 pt-10 flex items-start lg:items-center justify-center"
        >
          <form
            onSubmit={formik.handleSubmit}
            className="w-full lg:w-[850px] flex flex-wrap px-6 justify-between"
          >
            {" "}
            <div className=" col-lg-12 w-full">
              <FaTimes
                onClick={() => setshow2(false)}
                className="text-[#1B1F37] text-[24px] lg:text-[26px] lg:mb-5 cursor-pointer"
              />
            </div>
            <div className="col-lg-7">
              <span className="text-[#1b1f37]">
                قیمت : {data?.price?.toLocaleString()}تومان
              </span>
              <span className="p-0 col-12 mt-3 grid lg:grid-cols-5 whitespace-nowrap items-center">
                <label className="text-[#1B1F37] text-[14px] lg:text-[16px] lg:col-span-2">
                  نام و نام خانوادگی *
                </label>
                <input
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full mt-2 border border-[#515890] p-2 rounded-[4px] bg-transparent lg:col-span-3 text-[#1b1f37]"
                />
                {formik.errors.name && formik.touched.name && (
                  <small className="text-red-500">{formik.errors.name}</small>
                )}
              </span>

              <span className="p-0 col-12 mt-3 grid lg:grid-cols-5 whitespace-nowrap items-center">
                <label className="text-[#1B1F37] text-[14px] lg:text-[16px] lg:col-span-2">
                  ایمیل*
                </label>
                <input
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full mt-2 border border-[#515890] p-2 rounded-[4px] bg-transparent lg:col-span-3 text-[#1b1f37]"
                />
                {formik.errors.email && formik.touched.email && (
                  <small className="text-red-500">{formik.errors.email}</small>
                )}
              </span>
              <div className="lg:hidden flex justify-center flex-wrap mx-auto p-2 bg-[#E5E5E5] mt-5 w-full">
                <label
                  htmlFor="img"
                  className="relative w-full mt-2 border-dashed border-2 rounded-[2px] p-3 h-[250px] cursor-pointer border-[#A5A5A5] "
                >
                  {previewImg ? (
                    <img
                      src={previewImg}
                      className="w-full h-full object-contain"
                      alt=""
                    />
                  ) : (
                    <span className="absolute left-1/2 top-1/2 flex flex-col justify-center items-center -translate-x-1/2 -translate-y-1/2 text-[#767676] text-[14px] text-center">
                      <img src="/media and aoudio.png" alt="media and aoudio" />
                      لطفا رسید پرداخت خود را بارگذاری کنید
                    </span>
                  )}
                </label>
                <input
                  onChange={(e) => {
                    handleImg(e);
                  }}
                  type="file"
                  className="hidden"
                  id="img"
                  accept="image/png, image/jpg, image/jpeg"
                />
              </div>

              <span className="col-12 mt-5 p-0 flex justify-center">
                {loading ? (
                  <div
                    id="spinner"
                    className="show  d-flex align-items-center justify-content-center"
                  >
                    <div
                      className="spinner-border text-red-500"
                      style={{ width: "2rem", height: "2rem" }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="shop-now-link btn--e-brand w-full"
                  >
                    ارسال
                  </button>
                )}
              </span>
              <div className="mt-2">
                توجه : بعد از پرداختی ادمین های سایت رسید را چک میکنند و در صورت
                مطابقت برای شما فایل دوره را ارسال میکنند
              </div>
            </div>
            <div className="col-lg-5">
              <div className="lg:flex hidden justify-center flex-wrap mx-auto p-2 bg-[#E5E5E5] mt-5 w-full">
                <label
                  htmlFor="img"
                  className="relative w-full mt-2 border-dashed border-2 rounded-[2px] p-3 h-[250px] cursor-pointer border-[#A5A5A5] "
                >
                  {previewImg ? (
                    <img
                      src={previewImg}
                      className="w-full h-full object-contain"
                      alt=""
                    />
                  ) : (
                    <span className="absolute left-1/2 top-1/2 flex flex-col justify-center items-center -translate-x-1/2 -translate-y-1/2 text-[#767676] text-[14px] text-center">
                      <img src="/media and aoudio.png" alt="media and aoudio" />
                      لطفا رسید پرداخت خود را بارگذاری کنید
                    </span>
                  )}
                </label>
                <input
                  onChange={(e) => {
                    handleImg(e);
                  }}
                  type="file"
                  className="hidden"
                  id="img"
                  accept="image/png, image/jpg, image/jpeg"
                />
              </div>
            </div>
            {/* <span className="col-12 p-3">
                              {success && (
                                <Alert
                                  type={"success"}
                                  contnet={
                                    "اطلاعات شما با موفقیت ثبت شد و مورد بررسی قرار میگیرد"
                                  }
                                />
                              )}
                              {errors && <Alert type={"error"} contnet={errors} />}
                            </span> */}
          </form>
        </div>
      )}
      <CustomModal show={show} onHide={() => setshow(false)} fit>
        <div className="">
          <FaTimes
            className="mb-2 cursor-pointer"
            size={22}
            onClick={() => setshow(false)}
          />
          <div className="flex flex-col justify-center gap-2 items-center">
            <p className="text-center text-[16px]">
              برای گوش دادن به قسمت های بعد باید اشتراک تهیه کنی
            </p>
            <p className="text-red-600">
              اگه الان اشتراک بگیری به جای سه ماه یک ساله گرفتی
            </p>
            <Link
              to={"/dashboard/premium"}
              class="btn btn--e-brand-b-2  w-fit rounded-full py-2 px-4"
            >
              اشتراک
            </Link>
          </div>
        </div>
      </CustomModal>
      <Header />
      <div class="app-content px-4">
        <div class="u-s-p-t-60">
          <div class="container p-0">
            <div class="lg:flex items-center bg-[#fafafa] rounded-[30px] p-0 overflow-hidden w-full">
              <div class="col-lg-5 p-0">
                <div class="p-3">
                  <img
                    src={CDN_BASE_URL + data?.images[0]}
                    className="w-full max-h-[450px] object-cover rounded-[30px]"
                    alt={data?.name}
                  />
                  {/* <ProductSlider data={data?.images} /> */}
                </div>
              </div>
              <div class="col-lg-7 p-0">
                <div className="grid gap-3 lg:gap-5 lg:grid-cols-4 p-5">
                  <div class="pd-detail lg:col-span-3">
                    <Reveal width={"fit-content"}>
                      <div>
                        <span class="text-[#1B1F37] text-[18px] lg:text-[32px]">
                          {data?.name}
                        </span>
                      </div>
                    </Reveal>
                    <div class="u-s-m-b-15 u-s-m-t-5">
                      <Reveal width={"fit-content"}>
                        <div class="text-[#1B1F37] text-[18px]">
                          {data?.rate ? (
                            <>
                              <span class=" u-s-m-l-4">
                                <a data-click-scroll="#view-review">
                                  {data?.reviews?.length} نظر
                                </a>
                              </span>
                            </>
                          ) : (
                            <a data-click-scroll="#view-review">
                              هنوز نظر و امتیازی ثبت نشده
                            </a>
                          )}
                        </div>
                      </Reveal>
                    </div>
                  </div>
                  <span className="mt-5">
                    <RateToStar number={data?.rate} />
                  </span>
                  <div class="u-s-m-b-15 col-span-full">
                    <form class="pd-detail__form">
                      <Reveal>
                        <div class="pd-detail-inline-2">
                          <div class="u-s-m-y-10 w-full px-1">
                            {user ? (
                              <>
                                {data?.voice ? (
                                  <>
                                    {user.premium ? (
                                      <>
                                        <div
                                          className="w-full mt-3 fixed bottom-0 left-0 z-40 lg:relative  "
                                          dir="ltr"
                                        >
                                          <div className="flex justify-between mb-2 items-center pt-2 px-2">
                                            <Link
                                              download
                                              to={
                                                CDN_BASE_URL +
                                                data.files[currentTrack]
                                              }
                                              className="bg-[--brand] text-white p-2 rounded text-[12px]"
                                            >
                                              دانلود
                                            </Link>
                                            <p className="text-right font-bold text-black">
                                              قسمت {currentTrack + 1} از{" "}
                                              {data?.files.length}
                                            </p>
                                          </div>

                                          <AudioPlayer
                                            src={
                                              CDN_BASE_URL +
                                              data.files[currentTrack]
                                            }
                                            onPlay={(e) =>
                                              console.log("onPlay")
                                            }
                                            showSkipControls
                                            customAdditionalControls={[]}
                                            onClickNext={handleClickNext}
                                            onEnded={handleEnd}
                                            customIcons={{
                                              play: (
                                                <FaPlay
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                              next: (
                                                <FaAngleRight
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                              previous: (
                                                <FaAngleLeft
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                              forward: (
                                                <FaAngleDoubleRight
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                              rewind: (
                                                <FaAngleDoubleLeft
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                              pause: (
                                                <FaPause
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                              volume: (
                                                <FaVolumeHigh
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                              volumeMute: (
                                                <FaVolumeMute
                                                  color="#2A3056"
                                                  className="p-1"
                                                />
                                              ),
                                            }}
                                            // other props here
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <>
                                          <div
                                            className="w-full my-3 fixed bottom-0 left-0 z-40 lg:relative  "
                                            dir="ltr"
                                          >
                                            <div className="flex justify-between mb-2 items-center pt-2 px-2">
                                              <p className="text-right font-bold text-black">
                                                قسمت {currentTrack + 1} از{" "}
                                                {data?.files.length}
                                              </p>
                                            </div>

                                            <AudioPlayer
                                              src={
                                                CDN_BASE_URL +
                                                data.files[currentTrack]
                                              }
                                              customIcons={{
                                                play: (
                                                  <FaPlay
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                                next: (
                                                  <FaAngleRight
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                                previous: (
                                                  <FaAngleLeft
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                                forward: (
                                                  <FaAngleDoubleRight
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                                rewind: (
                                                  <FaAngleDoubleLeft
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                                pause: (
                                                  <FaPause
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                                volume: (
                                                  <FaVolumeHigh
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                                volumeMute: (
                                                  <FaVolumeMute
                                                    color="#2A3056"
                                                    className="p-1"
                                                  />
                                                ),
                                              }}
                                              showSkipControls
                                              customAdditionalControls={[]}
                                              onClickNext={
                                                handleClickNextNotPremium
                                              }
                                              onEnded={handleEnd}
                                              // other props here
                                            />
                                          </div>
                                        </>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        boxShadow: "2px 2px 4px 0px #eee",
                                      }}
                                      className="w-full p-5 gap-3 lg:gap-0 lg:p-3 flex flex-col lg:flex-row justify-center lg:justify-between items-center rounded-[16px]"
                                    >
                                      <div className="text-[#000] text-center lg:text-[20px] text-[16px]">
                                        قیمت : {data?.price?.toLocaleString()}
                                        تومان
                                      </div>
                                      <button
                                        onClick={() => setshow2(true)}
                                        type="button"
                                        class="btn btn--e-brand-b-2 block w-full lg:w-fit text-center"
                                      >
                                        خرید
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {!data?.voice ? (
                                  <>
                                    <div
                                      style={{
                                        boxShadow: "2px 2px 4px 0px #eee",
                                      }}
                                      className="w-full p-5 gap-3 lg:gap-0 lg:p-3 flex flex-col lg:flex-row justify-center lg:justify-between items-center rounded-[16px]"
                                    >
                                      <div className="text-[#000] text-center lg:text-[20px] text-[16px]">
                                        قیمت : {data?.price?.toLocaleString()}
                                        تومان
                                      </div>
                                      <button
                                        onClick={() => setshow2(true)}
                                        type="button"
                                        class="btn btn--e-brand-b-2 block w-full lg:w-fit text-center"
                                      >
                                        خرید
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="w-full fixed bottom-0 left-0 z-40 lg:relative bg-[#fafafa]"
                                      dir="ltr"
                                    >
                                      <div className="flex justify-between mb-2 items-center pt-2 px-2">
                                        <p className="text-right font-bold text-black">
                                          قسمت {currentTrack + 1} از{" "}
                                          {data?.files.length}
                                        </p>
                                      </div>

                                      <AudioPlayer
                                        src={
                                          CDN_BASE_URL +
                                          data.files[currentTrack]
                                        }
                                        style={{ color: "#000" }}
                                        customIcons={{
                                          play: (
                                            <FaPlay
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                          next: (
                                            <FaAngleRight
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                          previous: (
                                            <FaAngleLeft
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                          forward: (
                                            <FaAngleDoubleRight
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                          rewind: (
                                            <FaAngleDoubleLeft
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                          pause: (
                                            <FaPause
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                          volume: (
                                            <FaVolumeHigh
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                          volumeMute: (
                                            <FaVolumeMute
                                              color="#2A3056"
                                              className="p-1"
                                            />
                                          ),
                                        }}
                                        onPlay={(e) => console.log("onPlay")}
                                        showSkipControls
                                        customAdditionalControls={[]}
                                        onClickNext={handleClickNextNotPremium}
                                        onEnded={handleEnd}
                                        // other props here
                                      />
                                    </div>
                                    <div
                                      style={{
                                        boxShadow: "2px 2px 4px 0px #eee",
                                      }}
                                      className="w-full p-5 gap-3 lg:gap-0 lg:p-3 flex flex-col lg:flex-row justify-center lg:justify-between items-center mt-4 rounded-[16px]"
                                    >
                                      <div className="text-[#1B1F37] text-center lg:text-[16px] text-[12px]">
                                        برای شنیدن تمام قسمت ها اشتراک تهیه
                                        کنید.
                                      </div>
                                      <Link
                                        to={"/signup"}
                                        class="btn btn--e-brand-b-2 block w-full lg:w-fit text-center"
                                      >
                                        اشتراک
                                      </Link>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </Reveal>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="u-s-m-y-60 px-4">
          <div class="container  p-4">
            <div class="row">
              <h2 className="text-[20px] lg:text-[20px] mb-2 lg:mb-2 text-[#1B1F37]">
                درباره {data?.name}:
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: data?.desc }}
                class="w-full text-black font-[Shabnam-thin] text-[20px] lg:text-[20px]"
              />
            </div>
          </div>
        </div>

        <div class="u-s-m-y-60 px-4">
          <div class="container  p-4">
            <div class="row">
              <h2 className="text-[20px] lg:text-[40px] mb-6 lg:mb-8 text-[#292929]">
                نظرات
              </h2>
              <div className="w-full grid lg:grid-cols-3">
                <span className="lg:col-span-2 flex flex-col gap-3">
                  {data?.reviews?.map((review) => {
                    return (
                      <div class="border-2 border-[#D4D4D4] rounded-[8px] p-5">
                        <div class="flex justify-between items-center">
                          <span class="text-[#1B1F37] text-[18px]">
                            {review?.userId?.f_name} {review?.userId?.l_name}
                          </span>
                          <RateToStar number={review?.rate} />
                        </div>

                        <p class="text-[16px] text-[#292929] mt-3">
                          {review?.content}
                        </p>
                      </div>
                    );
                  })}
                </span>
                <div class=" rounded-[16px] p-4">
                  {isLogin ? (
                    <form class="pd-tab__rev-f2">
                      <p className="text-[#000] text-[20px]">
                        نظر شما درباره این محصول چیست؟
                      </p>
                      <div class="rev-f2__group">
                        <div class="u-s-m-y-15">
                          <textarea
                            class="border-[1px] border-[#737373] rounded-[4px] w-full outline-none h-[128px] p-3 text-[#737373]"
                            placeholder="نظر شما"
                            id="reviewer-text"
                            onChange={(e) => setContent(e.target.value)}
                            value={content}
                          ></textarea>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-[#292929] text-[20px]">
                          به این محصول امتیاز دهید:
                        </span>
                        <span
                          dir="ltr"
                          className="flex gap-1 items-center cursor-pointer"
                        >
                          {rate &&
                            Array(rate)
                              .fill("")
                              .map((item, index) => (
                                <i
                                  onClick={() => setrate(index + 1)}
                                  class="fa-solid fa-star text-[#FFCB3D]"
                                ></i>
                              ))}
                          {rate &&
                            Array(5 - rate)
                              .fill("")
                              .map((item, index) => (
                                <i
                                  onClick={() => setrate(rate + index + 1)}
                                  class="fa-solid fa-star text-[#D9D9D9]"
                                ></i>
                              ))}
                          {!rate &&
                            Array(5)
                              .fill("")
                              .map((item, index) => (
                                <i
                                  onClick={() => setrate(index + 1)}
                                  class="fa-solid fa-star text-[#D9D9D9]"
                                ></i>
                              ))}
                        </span>
                      </div>
                      <div className="mt-4">
                        <button
                          class="btn w-full text-[18px] text-[#029B5B] border rounded-full border-[#029B5B]"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          ثبت نظر و امتیاز
                        </button>
                      </div>
                    </form>
                  ) : (
                    <div class="pd-tab__rev-f2">
                      <span className="text-[#292929] text-[20px] text-center flex flex-col items-center justify-center">
                        برای ثبت نظر باید ثبت نام کنید
                        <Link
                          to={"/signup"}
                          class="btn  text-[18px] text-[#029B5B] border rounded-full border-[#029B5B] mt-3"
                        >
                          ثبت نام
                        </Link>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="u-s-p-b-90 bg-[#fafafa] ">
          <div class="section__content">
            <div class="container">
              <div className="w-full flex justify-between text-[#292929] px-5 py-8">
                <h2 className="text-[24px] lg:text-[40px] ">محصولات مشابه</h2>
              </div>
              <Swiper
                breakpoints={{
                  100: { slidesPerView: 1 },
                  400: { slidesPerView: 2 },
                  768: { slidesPerView: 3 },
                  1024: { slidesPerView: 4 },
                }}
                spaceBetween={20}
              >
                {data2?.map((e) => (
                  <SwiperSlide>
                    <ProductCard full data={e} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
