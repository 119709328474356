import axios from "axios";

import React, { useCallback, useState } from "react";
import {
  FaEnvelope,
  FaInstagram,
  FaPhone,
  FaTelegramPlane,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";
import Header from "../components/layout/Header";
import Reveal from "../components/motion/Reveal";
import Loading from "../components/common/Loading";
import Footer from "../components/layout/Footer";
import { Helmet } from "react-helmet";
import RoutesContent from "../components/layout/RoutesContent";

const Contact = () => {
  const [name, setname] = useState();
  const [phone, setphone] = useState();
  const [content, setcontent] = useState();
  const [loading, setloading] = useState();

  async function handleSend(e) {
    e.preventDefault();
    if (!name || !phone || !content) {
      return toast.error("فیلد ها رو کامل کنید");
    }
    setloading(true);

    // const formdata = new FormData();
    // formdata.append("name", name);
    // formdata.append("phone", phone);
    await axios({
      url: BASE_URL + "/send",
      method: "POST",
      data: { name, content, phone },
    })
      .then((e) => {
        toast.success("موفقیت آمیز");
        setname("");
        setphone("");
        setcontent("");
        setloading(false);
      })
      .catch((err) => {
        toast.error(err);
        setname("");
        setphone("");
        setcontent("");
        setloading(false);
      });
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"سیریر | ارتباط با ما"}</title>
        <meta
          name="description"
          content={
            "سیریر، بستری برای ارتقاء توانمندی‌ها و پیشرفت شخصی و حرفه‌ای"
          }
        />
        <meta
          name="og:description"
          content={
            "سیریر، بستری برای ارتقاء توانمندی‌ها و پیشرفت شخصی و حرفه‌ای"
          }
        />
        <meta name="og:title" content={"سیریر | ارتباط با ما"} />
      </Helmet>
      <Header />
      <main
        id="main"
        data-aos="fade"
        data-aos-delay="1500"
        class="pt-6 lg:pt-24 pb-10 text-[#1a1a1a]"
      >
        <div class="page-header flex items-center">
          <div class="container relative">
            <div class="row flex justify-center">
              <div class="lg:w-1/2 text-center">
                <Reveal>
                  <h1 class="text-[25px] lg:text-[42px] text-[#1a1a1a]">
                    ارتباط با ما
                  </h1>
                </Reveal>
              </div>
            </div>
          </div>
        </div>

        <div class="contact">
          <div class=" max-w-[920px] mx-auto">
            <div class="grid lg:grid-cols-3 gap-4 ">
              <div>
                <Reveal>
                  <a
                    href="https://t.me/Mahyarbbz"
                    class="info-item flex items-center gap-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="bg-[#F5F5F5] rounded-full p-2">
                      <FaTelegramPlane color="#142819" size={35} />
                    </span>

                    <div>
                      <h4 class="font-semibold">تلگرام:</h4>
                      <p>@Mahyarbbz</p>
                    </div>
                  </a>
                </Reveal>
              </div>

              <div>
                <Reveal>
                  <a
                    href="mailto:wimehr@gmail.com"
                    class="info-item flex items-center gap-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="bg-[#F5F5F5] rounded-full p-2">
                      <FaEnvelope color="#142819" size={35} />
                    </span>
                    <span className="bg-[#f5f5f5]"></span>

                    <div>
                      <h4 class="font-semibold">ایمیل:</h4>
                      <p>wimehr@gmail.com</p>
                    </div>
                  </a>
                </Reveal>
              </div>

              <div>
                <Reveal>
                  <a
                    href="https://www.instagram.com/mahyar.babazadeh/"
                    class="info-item flex items-center gap-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="bg-[#F5F5F5] rounded-full p-2">
                      <FaInstagram color="#142819" size={35} />
                    </span>
                    <div>
                      <h4 class="font-semibold">اینستاگرام</h4>
                      <p dir="ltr">mahyar.babazadeh</p>
                    </div>
                  </a>
                </Reveal>
              </div>
            </div>

            <div class="mt-4 w-full">
              <div>
                <form class="php-email-form pb-5 px-2 w-full ">
                  <div class=" grid lg:grid-cols-2 gap-4 w-full">
                    <div class=" form-group">
                      <Reveal>
                        <input
                          type="text"
                          name="name"
                          class="rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343] h-[48px] w-full p-3"
                          id="name"
                          value={name}
                          placeholder="نام"
                          onChange={(e) => setname(e.target.value)}
                          required
                        />
                      </Reveal>
                    </div>
                    <div class=" form-group ">
                      <Reveal>
                        <input
                          type="text"
                          class="rounded-full outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343] h-[48px] w-full p-3"
                          name="phone"
                          value={phone}
                          id="phone"
                          onChange={(e) => setphone(e.target.value)}
                          placeholder="شماره تماس"
                          required
                        />
                      </Reveal>
                    </div>
                    <div class="lg:col-span-2">
                      <Reveal>
                        <textarea
                          class="rounded-[14px] outline-none bg-[#F5F5F5] placeholder:text-[#A5A5A5] text-[#434343] w-full h-[128px] p-4"
                          onChange={(e) => setcontent(e.target.value)}
                          value={content}
                          name="content"
                          rows="5"
                          placeholder="محتوای پیام"
                          required
                        />
                      </Reveal>
                    </div>
                  </div>

                  <div class="text-center mt-4">
                    {loading ? (
                      <Loading />
                    ) : (
                      <Reveal>
                        <button
                          onClick={handleSend}
                          type="submit"
                          class="btn--e-brand py-2 px-6"
                        >
                          ارسال
                        </button>
                      </Reveal>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <RoutesContent />
      </main>
      <Footer />
    </>
  );
};

export default Contact;
