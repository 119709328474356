import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import ColorSwitcher from "../components/layout/ColorSwitcher";
import Slider from "../components/home/Slider";
import HelpYou from "../components/home/HelpYou";
import TopProducts from "../components/home/TopProducts";
import Dontmiss from "../components/home/Dontmiss";
import Reviews from "../components/home/Reviews";
import Footer from "../components/layout/Footer";
import StartNow from "../components/home/StartNow";
import FAQ from "../components/home/FAQ";
import TextWelcome from "../components/home/TextWelcome";
import { Helmet } from "react-helmet";
import PartnerLogos from "../components/home/PartnerLogos";
import HomeCounter from "../components/home/HomeCounter";
import { httpReauest } from "../utils/httprequest";
import BannerSlider from "../components/home/BannerSlider";
import WimehrSection from "../components/home/WimehrSection";
import RoutesContent from "../components/layout/RoutesContent";
import CtaToTour from "./CtaToTour";

const Home = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const newData = await httpReauest("GET", "/portfolio", {}, {});

    setData(newData.data.data);
  }
  return (
    <div>
      <Helmet>
        <title>{"شبکه هوش برتر"}</title>
        <meta
          name="description"
          content={
            "سیریر، بستری برای ارتقاء توانمندی‌ها و پیشرفت شخصی و حرفه‌ای"
          }
        />
        <meta
          name="og:description"
          content={
            "سیریر، بستری برای ارتقاء توانمندی‌ها و پیشرفت شخصی و حرفه‌ای"
          }
        />
        <meta name="og:title" content={"شبکه هوش برتر"} />
      </Helmet>{" "}
      <Header />
      <div class="app-content bg-[#fafafa]">
        <Slider />
        <BannerSlider data={data} />
        <CtaToTour />
        <HelpYou />
        <WimehrSection />
        <HomeCounter />
        <TextWelcome />
        <Dontmiss />
        <PartnerLogos data={data} />
        <TopProducts />
        <StartNow />

        {/* <Reviews /> */}
        <FAQ />
        <RoutesContent />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
