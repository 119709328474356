import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { httpReauest } from "../utils/httprequest";
import Header from "../components/layout/Header";
import { CDN_BASE_URL } from "../config";
import Footer from "../components/layout/Footer";
import { Helmet } from "react-helmet";

const PortfolioPage = () => {
  const { id } = useParams();

  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  async function getData(params) {
    await httpReauest("GET", "/portfolio/" + id, {}, {}).then((res) =>
      setData(res.data.data)
    );
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.name}</title>
        <meta name="description" content={data?.desc} />
        <meta name="og:description" content={data?.desc} />
        <meta name="og:title" content={data?.name} />
      </Helmet>
      <Header />
      <div class="app-content">
        <div className="w-full flex flex-col px-3 lg:px-5 justify-center items-center py-2 my-5 container text-black ">
          <img
            src={CDN_BASE_URL + data?.logo}
            style={{ width: "280px" }}
            alt={data?.name}
          />
          <h3 className="mt-4 title-primary text-center">{data?.name}</h3>
          <div
            className="mt-2 mb-5"
            dangerouslySetInnerHTML={{ __html: data?.desc }}
          />
          {data?.images?.map((img) => {
            return (
              <img
                src={CDN_BASE_URL + img}
                className="w-100 my-5 img_max"
                alt={data?.name}
                loading="lazy"
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PortfolioPage;
