import React from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";

const TextWelcome = () => {
  return (
    <div className=" py-16  relative  text-center px-3 bg-[#e7ab04] grid lg:grid-cols-2 lg:px-20 items-center gap-5 lg:gap-0">
      <div
        style={{ backgroundImage: "url(Frame.png)", backgroundSize: "1050px" }}
        className="w-full h-full left-0 top-0 absolute opacity-30 bg-contain z-0"
      ></div>
      <span className="text-[#131734]  flex flex-col gap-2 lg:gap-4 items-center relative z-10">
        <p className="text-[20px] lg:text-[28px] font-bold">
          مجموعه کتاب های صوتی برای پیشرفت
        </p>
        <h3
          style={{ textShadow: " 2px 2px  #000", lineHeight: "48px" }}
          className="text-[#fff] text-[26px] lg:text-[45px] font-bold mb-2 mt-2 whitespace-nowrap"
        >
          در زندگی شخصـی و حرف‍‍ـه ای
        </h3>
        <p className="text-[14px] lg:text-[20px] font-bold mt-4">
          نمونه (ژن خودخواه - ریچارد داکینز):
        </p>
        <audio
          src={
            CDN_BASE_URL +
            "uploads/market1733756624222SG00.mp3"
          }
          controls
        />
        <Link
          to={"/signin"}
          className="btn--e-brand py-2 px-6 text-[18px] hidden lg:block"
        >
          مشترک شو!
        </Link>
      </span>{" "}
      <span className="relative z-10">
        <img src="/books.png" className="w-full" alt="Sirir Books" />
      </span>
      <span className="lg:hidden text-[#131734] relative z-10">
        <Link to={"/signup"} className="btn--e-brand py-2 px-6 text-[18px]">
          مشترک شو!
        </Link>
      </span>
    </div>
  );
};

export default TextWelcome;
