import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const AccordionRoutes = ({ question, value }) => {
  const [show, setShow] = useState(false);
  return (
    <div class="bg-[#fff] shadow  rounded-[8px] px-7 py-5 ">
      <button
        onClick={() => setShow(!show)}
        class="w-full flex justify-between items-center  text-[#292929] text-[16px] lg:text-[22px]"
      >
        <span>{question}</span>
        <span
          id="icon-1"
          class={`${
            show ? "rotate-180" : ""
          }  transition-transform duration-300`}
        >
          <FaAngleDown />
        </span>
      </button>
      <div
        id="content-1"
        class={`${
          show ? "max-h-96" : "max-h-0"
        } overflow-hidden transition-all duration-300 ease-in-out `}
      >
        <div className="pt-5 text-black" dangerouslySetInnerHTML={{ __html: value }} />
      </div>
    </div>
  );
};

export default AccordionRoutes;
