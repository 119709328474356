import React from "react";
import Reveal from "../components/motion/Reveal";
import { Link } from "react-router-dom";

const CtaToTour = () => {
  return (
    <section className="pt-8 pb-12 lg:py-16 relative">
      <div
        style={{
          background:
            "linear-gradient(45deg, rgba(0,0,0,1) 12%, rgba(73,1,187,0.8883928571428571) 59%, rgba(246,90,6,1) 89%)",
        }}
        className="absolute w-[106%] h-full -left-5 -top-4 blur-lg "
      ></div>

      <div class="gap-6 items-center py-8 px-4  mx-auto max-w-7xl w-[95%] lg:grid lg:grid-cols-5 lg:py-8 lg:px-8 z-10 backdrop-blur-sm bg-[#44180b6e] rounded-lg shadow-2xl shadow-purple-500/70 ">
        <div class="font-light text-gray-200 col-span-2">
          <Reveal head>
            <h2 class="mb-4 text-[24px] lg:text-[42px] font-extrabold text-gray-100 ">
              تور NEXT LEVEL
            </h2>
            <p class="mb-6 text-[16px] leading-8">
              فستیوال زیبایی بین المللی ترکیه شهر آنتانیا <br /> - هتل 5 ستاره
              آنتالیا
              <br />
              3 شب و چهار روز
              <br />5 شب و شش روز
            </p>
            <div className="">
              <Link
                to={"/event/67c979af66c3cccfec2ac81c"}
                className="btn--e-primary py-2 px-6 text-[14px] lg:text-[16px] "
              >
                ثبت نام
              </Link>
            </div>
          </Reveal>
        </div>
        <div class="grid grid-cols-2 col-span-3 gap-4 mt-8">
          <Reveal head>
            <img
              class="w-full rounded-lg shadow"
              src="https://api.sirir.ir/uploads/market1742840635103photo_2025-03-06_13-55-00.jpg"
              alt=""
            />
          </Reveal>
          <Reveal head>
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg shadow"
              src="https://api.sirir.ir/uploads/market1742840635108photo_2025-03-05_14-12-58.jpg"
              alt="office content 2"
            />
          </Reveal>
        </div>
      </div>
    </section>
  );
};

export default CtaToTour;

