import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Lottie from "lottie-react";
import BrandingAnimation from "../lottie/branding.json";
import TalkAnimation from "../lottie/talk.json";
import ContentAnimation from "../lottie/content.json";
import MentorAnimation from "../lottie/mentor.json";
import SuppourtAnimation from "../lottie/suppourt.json";
import KuchingAnimation from "../lottie/kuching.json";
import Reveal from "../components/motion/Reveal";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import StartNow from "../components/home/StartNow";
import FAQ from "../components/home/FAQ";
import { httpReauest } from "../utils/httprequest";
import { CDN_BASE_URL } from "../config";
import { Helmet } from "react-helmet";
import RoutesContent from "../components/layout/RoutesContent";

export const SrviceInfo = [
  {
    id: 1,
    animation: TalkAnimation,
    title: "مشاوره",
    img: "/Consulting.png",
    title_en: "Consulting",
    ico: "fa-solid fa-comment",
    subTitle: "راهنمایی شخصی و حرفه‌ای در جهت توسعه شما!",
    desc: "در بخش مشاوره 'سیریر'، تجربه‌ای فوق‌العاده برای شناخت بهتر خود و بهبود شخصی و حرفه‌ایتان منتظرتان است. مشاوران ما، همراه شما در سفری انگیزشی برای کشف استعدادها و پتانسیل‌هایتان خواهند بود. اینجا جایی است که رویای بهترین نسخه از خود را می‌سازید. با 'سیریر'، بیشتر از تنها مشاوره، یک تغییر زندگی را تجربه خواهید کرد!",
  },
  {
    id: 2,
    animation: BrandingAnimation,
    title: "طراحی سایت و سئو",
    title_en: "Seo & Web design",
    img: "/Seo & Web design.jpg",
    ico: "fa-solid fa-image",
    subTitle: "شعله‌ور و مبتکر در دنیای دیجیتال!",
    desc: "با ورود به بخش تبلیغات 'سیریر'، ما به برندها و کسب و کارها فرصتی فراهم می‌کنیم تا به طرقی نوآورانه و شورانگیز در دنیای دیجیتال معرفی شوند. اینجا جایی است که افکار شما به آتش می‌زند و با همکاری با اینفلوئنسرهای برجسته، میلیونها چشم به راه شما خواهند بود. با 'سیریر'، از فضای آنلاین به طور شگفت‌آوری بهره‌مند شوید و برند خود را در دنیای دیجیتال به اوج ببرید!",
  },
  {
    id: 3,
    animation: BrandingAnimation,
    title_en: "Advertising",
    img: "/Advertising.jpg",
    title: "تبلیغات",
    ico: "fa-solid fa-rectangle-ad",
    subTitle: "شعله‌ور و مبتکر در دنیای دیجیتال!",
    desc: "با ورود به بخش تبلیغات 'سیریر'، ما به برندها و کسب و کارها فرصتی فراهم می‌کنیم تا به طرقی نوآورانه و شورانگیز در دنیای دیجیتال معرفی شوند. اینجا جایی است که افکار شما به آتش می‌زند و با همکاری با اینفلوئنسرهای برجسته، میلیونها چشم به راه شما خواهند بود. با 'سیریر'، از فضای آنلاین به طور شگفت‌آوری بهره‌مند شوید و برند خود را در دنیای دیجیتال به اوج ببرید!",
  },
  {
    id: 4,
    animation: KuchingAnimation,
    title: "کوچینگ",
    title_en: "Coaching",
    img: "/coaching.jpg",
    ico: "fa-solid fa-chalkboard-user",
    subTitle: "راهنمایی شگفت‌آور برای نسل آینده!",
    desc: "در جهانی پر از چالش و فرصت، 'کوچینگ' ما با تیمی از مشاوران ماهر و ابزارهایی پویا، به دختران و پسران پیشرو کمک می‌کند تا آینده‌ی خود را با اعتماد به نفس و مهارت‌های لازم کشف کنند. با 'سیریر'، خوشآمدید به سفری انگیزشی از راهنمایی و توسعه فردی!",
  },

  {
    id: 5,
    animation: ContentAnimation,
    title: "تولید محتوا",
    title_en: "Content Production",
    img: "/Content Production.jpg",
    ico: "fa-solid fa-file-signature",
    subTitle: "ما جنبه‌های منحصر به فرد شما را زنده می‌کنیم!",
    desc: "در 'سیریر'، تولید محتوا برای شما هنر است. از داستان‌های جذاب گرفته تا محتوای آموزشی فوق‌العاده، ما به شکلی کارآمد و خلاق محتوا را ایجاد می‌کنیم. با تیمی پویا و متخصص، محتوای ما با قلاب‌های شگفت‌انگیز شما را در طولانی‌ترین خاطرات تبدیل می‌کند. با 'سیریر'، هر محتوایی یک داستان زندگی است!",
  },
  {
    id: 6,
    animation: ContentAnimation,
    title: "فرنچایز",
    title_en: "Franchise",
    img: "/Franchise.jpg",
    ico: "fa-regular fa-building",
    subTitle: "ما جنبه‌های منحصر به فرد شما را زنده می‌کنیم!",
    desc: "در 'سیریر'، تولید محتوا برای شما هنر است. از داستان‌های جذاب گرفته تا محتوای آموزشی فوق‌العاده، ما به شکلی کارآمد و خلاق محتوا را ایجاد می‌کنیم. با تیمی پویا و متخصص، محتوای ما با قلاب‌های شگفت‌انگیز شما را در طولانی‌ترین خاطرات تبدیل می‌کند. با 'سیریر'، هر محتوایی یک داستان زندگی است!",
  },
];

const ServiceDetails = () => {
  const [data, setData] = useState();
  const [portfolios, setportfolios] = useState();
  const { id } = useParams();
  useEffect(() => {
    getData();
  }, [useLocation()]);

  async function getData(params) {
    const newdata = SrviceInfo.find((e) => e.id == id);
    setData(newdata);

    await httpReauest("GET", "/portfolio?type=" + newdata.title, {}, {}).then(
      (res) => setportfolios(res.data.data)
    );
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.title}</title>
        <meta name="description" content={data?.desc} />
        <meta name="og:description" content={data?.desc} />
        <meta name="og:title" content={data?.title} />
      </Helmet>
      <Header />
      <div class="app-content">
        <div class="bg-[#fff] py-10 lg:pt-20 lg:pb-8 px-3">
          <div class=" ">
            <div class="container ">
              <div class="row flex-col-reverse lg:flex-row items-center  lg:p-4 bg-[#FAFAFA] rounded-[15px] overflow-hidden">
                <div class="col-lg-6 text-[#292929] py-3">
                  <Reveal>
                    <div class="text-[24px] lg:text-[48px] font-[500] pb-1">
                      {data?.title}
                    </div>
                  </Reveal>
                  <Reveal>
                    <div class="text-[16px] lg:text-[24px] pb-3">
                      {data?.subTitle}{" "}
                    </div>
                  </Reveal>
                  <Reveal width={"fit-content"}>
                    <div class="text-[12px] lg:text-[16px] font-[Shabnam-thin] ">
                      {" "}
                      {data?.desc}
                    </div>
                  </Reveal>
                  <div className="flex justify-end mt-5">
                    <Reveal width={"fit-content"}>
                      <Link
                        to={"/contact"}
                        className="btn--e-primary text-[14px] px-3 py-1 flex items-center gap-2"
                      >
                        شروع مشاوره <FaAngleLeft />
                      </Link>
                    </Reveal>
                  </div>
                </div>
                <div className="col-lg-6 bg-[#F0F8FF]">
                  <Reveal head width={"fit-content"}>
                    <Lottie animationData={data?.animation} loop={true} />
                  </Reveal>
                </div>
              </div>
            </div>
          </div>

          {portfolios?.length ? (
            <>
              <div class="my-10 lg:my-20">
                <div class="container ">
                  <h3 className="text-[22px] lg:text-[32px] text-[#292929]">
                    نمونه کارهای ری اکت (وب اپ)
                  </h3>
                  <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-12">
                    {portfolios?.map((item) => {
                      if (item?.category === "ری اکت (وب اپ)") {
                        return (
                          <Link
                            to={"/portfolio/" + item?._id}
                            className="hover:scale-95 scale-100 duration-200"
                          >
                            <span className="">
                              <img
                                src={CDN_BASE_URL + item?.img}
                                alt={item?.name}
                                className="rounded-[8px] shadow "
                              />
                              <h4 className="pt-4  text-center font-bold text-black text-[24px] ">
                                {item?.name}
                              </h4>
                              <p className="w-full text-center">
                                ({item?.subCategory})
                              </p>
                            </span>
                          </Link>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <div class="my-10 lg:my-20">
                <div class="container ">
                  <h3 className="text-[22px] lg:text-[32px] text-[#292929]">
                    نمونه کارهای وردپرسی
                  </h3>
                  <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-12">
                    {portfolios?.map((item) => {
                      if (item?.category === "وردپرس") {
                        return (
                          <Link
                            to={"/portfolio/" + item?._id}
                            className="hover:scale-95 scale-100 duration-200"
                          >
                            <span className="">
                              <img
                                src={CDN_BASE_URL + item?.img}
                                alt={item?.name}
                                className="rounded-[8px] shadow "
                              />
                              <h4 className="pt-4  text-center font-bold text-black text-[24px] ">
                                {item?.name}
                              </h4>
                            </span>
                          </Link>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div class="my-10 lg:my-20">
            <div class="container ">
              <h3 className="text-[22px] lg:text-[32px] text-[#292929]">
                خدمات دیگر
              </h3>
              <div className="mt-10 grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-12">
                {SrviceInfo.map((item) => {
                  if (item.id != id) {
                    return (
                      <Link to={"/service/" + item.id}>
                        <span className="bg-[#2A3056] rounded-full text-[14px] lg:text-[24px] py-3 flex items-center justify-center text-white text-center px-4 lg:px-5">
                          {item?.title}
                        </span>
                      </Link>
                    );
                  }
                })}
                <Link to={"/products/audio"}>
                  <span className="bg-[#2A3056] rounded-full text-[14px] lg:text-[24px] py-3 flex items-center justify-center text-white text-center px-4 lg:px-5">
                    کتاب های صوتی
                  </span>
                </Link>
                <Link to={"/event"}>
                  <span className="bg-[#2A3056] rounded-full text-[14px] lg:text-[24px] py-3 flex items-center justify-center text-white text-center px-4 lg:px-5">
                    تورها
                  </span>
                </Link>
                <Link to={"/products/courses"}>
                  <span className="bg-[#2A3056] rounded-full text-[14px] lg:text-[24px] py-3 flex items-center justify-center text-white text-center px-4 lg:px-5">
                    دوره های آموزشی
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <StartNow />
          <FAQ />
          <RoutesContent />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServiceDetails;
