import React from "react";
import Reveal from "../motion/Reveal";
import Accordion from "../common/Accordion";
import { FAQ_QUESTIONS } from "../../data/faq";
import { useLocation } from "react-router-dom";

const FAQ = () => {
  const location = useLocation();

  console.log(location)
  return (
    <div class="u-s-p-y-40 ">
      <div className="section__intro u-s-m-b-30">
        <div className="container">
          <div>
            <Reveal head>
              <div className="w-full flex justify-between text-[#292929] px-5">
                <h2 className="text-[20px] lg:text-[36px] ">سوالات متداول</h2>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="section__content left-right-section py-6">
        <div className="container">
          <div className="row gap-6">
            {FAQ_QUESTIONS.map((item) => {
              
              if (location.pathname === item.category) {
                return (
                  <div className="col-lg-12">
                    <Accordion question={item.question} value={item.answer} />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
