export const FAQ_QUESTIONS = [
  {
    question: "سیریر (SIRIR) دقیقاً چیه و چه کاری انجام می‌ده؟",
    answer:
      "سیریر یه شبکه هوش برتره که هدفش رشد، توسعه و ارتقای توانمندی‌های فردی و کسب‌وکارهاست. ما تو این مسیر، خدمات توسعه آنلاین و دیجیتال، تورهای تخصصی و فرانچایز رو ارائه می‌دیم. همه‌ی اینا برای اینه که شما بتونید تو دنیای امروز یه قدم جلوتر از بقیه باشید!",
    category: "/",
  },
  {
    question: "چطوری می‌تونم عضو سیریر بشم؟",
    answer:
      "خیلی ساده! شماره موبایلت رو وارد کن، یه پیامک تأیید دریافت می‌کنی، کد رو وارد کن و تموم! حالا به دنیای هوش برتر خوش اومدی.",
    category: "/",
  },
  {
    question: "خدمات سیریر رایگانه یا پولی؟",
    answer:
      "ما یه سری خدمات رایگان داریم که به رشد و آگاهی شما کمک می‌کنه، ولی اگه دنبال تجربه حرفه‌ای‌تر و استفاده از امکانات پیشرفته‌تر هستی، می‌تونی از طرح‌های ویژه و خدمات تخصصی ما استفاده کنی. برای اطلاعات بیشتر، یه سر به بخش تعرفه‌ها بزن.",
    category: "/",
  },
  {
    question: "چطور می‌تونم با تیم سیریر در تماس باشم؟",
    answer:
      "هر سوالی داشتی، ما اینجاییم! می‌تونی از طریق فرم تماس با ما پیام بدی، به شماره 09111531708 پیامک بزنی، یا با 09100540105 ارتباط بگیری. چت آنلاین هم داریم که سریع جوابت رو بدیم.",
    category: "/",
  },
  {
    question: "تورهای تخصصی سیریر شامل چی می‌شه؟",
    answer:
      "تورهای ما فقط یه سفر ساده نیستن! این تورها برای افراد و کسب‌وکارهایی طراحی شدن که می‌خوان تو حوزه خودشون یه جهش بزرگ داشته باشن. از بازدید از هاب‌های تکنولوژی و استارتاپ‌های موفق گرفته تا ورکشاپ‌های حرفه‌ای و جلسات شبکه‌سازی. یه تجربه خاص که تو هیچ جای دیگه پیداش نمی‌کنی!",
    category: "تورهای تخصصی",
  },
  {
    question: "فرانچایز سیریر چطور کار می‌کنه؟",
    answer:
      "اگر دنبال رشد سریع کسب‌وکار خودت هستی یا می‌خوای یه بیزینس موفق رو تو شهر خودت راه‌اندازی کنی، سیستم فرانچایز سیریر دقیقاً همون چیزیه که نیاز داری. ما بهت مشاوره، راهکار و پشتیبانی می‌دیم تا با یه مدل تست‌شده و موفق، سریع‌تر وارد بازار بشی و پیشرفت کنی.",
    category: "/service/6",
  },
  {
    question: "توسعه آنلاین و دیجیتال سیریر شامل چه خدماتیه؟",
    answer:
      "ما اینجا هستیم که حضورت تو دنیای دیجیتال رو قوی‌تر کنیم! از طراحی و توسعه سایت و اپلیکیشن گرفته تا برندینگ، استراتژی دیجیتال، سئو و تبلیغات حرفه‌ای. هر چیزی که نیاز داری تا آنلاین بدرخشی، توی سیریر برات فراهم شده.",
    category: "/service/2",
  },
  {
    question: "چطور می‌تونم تو پروژه‌های سیریر همکاری کنم؟",
    answer:
      "اگر فکر می‌کنی می‌تونی بخشی از شبکه هوش برتر باشی و ارزش جدیدی ایجاد کنی، خوشحال می‌شیم ازت بشنویم! می‌تونی از طریق فرم همکاری با ما اطلاعاتت رو ارسال کنی تا بررسی کنیم و در صورت امکان باهات ارتباط بگیریم.",
    category: "/",
  },
  {
    question: "آیا برای استفاده از خدمات سیریر نیاز به تجربه قبلی دارم؟",
    answer:
      "نه لزوماً! ما خدمات رو طوری طراحی کردیم که هم افراد تازه‌کار بتونن ازشون استفاده کنن، هم حرفه‌ای‌ها و کسب‌وکارهای بزرگ. مهم اینه که بخوای سطح بالاتری رو تجربه کنی، ما کنارت هستیم.",
    category: "/",
  },
  {
    question:
      "بعد از ثبت‌نام، چطور از آخرین برنامه‌ها و آپدیت‌های سیریر باخبر بشم؟",
    answer:
      "بهترین راه اینه که نوتیفیکیشن‌های پیامکی ما رو فعال کنی تا جدیدترین تورها، خدمات و فرصت‌های ویژه رو از دست ندی. همین‌طور می‌تونی ما رو توی شبکه‌های اجتماعی دنبال کنی تا همیشه در جریان آخرین اخبار و برنامه‌ها و تخفیف ها باشی.",
    category: "/event/67c979af66c3cccfec2ac81c",
  },
  {
    question: "چرا طراحی سایت با React رو انتخاب کردید؟",
    answer:
      "ما تو سیریر همیشه دنبال بهترین تکنولوژی‌ها هستیم، و React یکی از قوی‌ترین و سریع‌ترین فریم‌ورک‌های توسعه وبه. سایت‌هایی که با React طراحی می‌کنیم، سرعت بالا، امنیت قوی، تجربه کاربری روان و قابلیت مقیاس‌پذیری عالی دارن. یعنی یه سایت مدرن که به راحتی رشد می‌کنه و همیشه یه قدم جلوتره!",
    category: "/service/2",
  },
  {
    question: "مراحل طراحی سایت در سیریر چطوریه؟",
    answer:
      "ما یه مسیر ساده ولی حرفه‌ای رو دنبال می‌کنیم:\n1. مشاوره و نیازسنجی – اول از همه، با هم بررسی می‌کنیم که دقیقاً چه چیزی نیاز داری.\n2. طراحی رابط کاربری (UI/UX) – یه طرح زیبا، کاربرپسند و حرفه‌ای برای سایتت آماده می‌کنیم.\n3. کدنویسی و توسعه با React – سایتت رو با استفاده از جدیدترین متدهای React توسعه می‌دیم.\n4. بهینه‌سازی و تست نهایی – سرعت، امنیت و عملکرد سایت رو چک می‌کنیم تا بی‌نقص باشه.\n5. راه‌اندازی و پشتیبانی – سایتت رو لانچ می‌کنیم و همیشه کنارت هستیم برای پشتیبانی و به‌روزرسانی.",
    category: "/service/2",
  },
  {
    question:
      "آیا خدمات سئو سیریر فقط برای سایت‌هایی هست که خودتون طراحی کردید؟",
    answer:
      "نه، ما روی هر سایتی که داری، می‌تونیم سئو انجام بدیم! چه سایتت رو قبلاً جای دیگه طراحی کرده باشی، چه تازه بخوای سایتت رو سئو کنی، تیم ما یه استراتژی حرفه‌ای برای بهینه‌سازی سایتت پیاده می‌کنه تا رتبه بالاتری تو گوگل بگیری و بیشتر دیده بشی.",
    category: "/service/2",
  },
  {
    question: "چطور می‌تونم بفهمم که سئوی سایتم موفق بوده؟",
    answer:
      "ما بهت گزارش‌های دقیق از رتبه سایت در گوگل، میزان بازدید، رفتار کاربران و رشد کلمات کلیدی می‌دیم. اینطوری دقیقاً می‌تونی ببینی که سایتت چقدر پیشرفت کرده و چطور داره توی نتایج جستجو بالا میاد.",
    category: "/service/2",
  },
  {
    question: "آیا خدمات سئو شامل تولید محتوا هم می‌شه؟",
    answer:
      "بله! تولید محتوای حرفه‌ای یکی از مهم‌ترین بخش‌های سئوئه، و ما یه تیم متخصص داریم که محتوای بهینه‌شده برای گوگل و جذاب برای کاربر تولید می‌کنه. این محتوا می‌تونه شامل مقالات، بلاگ، توضیحات محصول و حتی اسکریپت‌های ویدیویی باشه.",
    category: "/service/2",
  },
  {
    question: "سیریر تبلیغات دیجیتال رو چطور اجرا می‌کنه؟",
    answer:
      "سیریر تبلیغات دیجیتال رو با استفاده از استراتژی‌های به‌روز و مبتنی بر داده اجرا می‌کنه. تیم ما با به‌کارگیری تکنیک‌های پیشرفته سئو، تبلیغات در شبکه‌های اجتماعی و بهینه‌سازی محتوا، هدفش افزایش رتبه سایت در گوگل و جذب مخاطب هدفه.",
    category: "/service/3",
  },
  {
    question: "چه تکنیک‌هایی برای بهبود رتبه گوگل به کار می‌برید؟",
    answer:
      "ما بر پایه اصول بهینه‌سازی محتوا، تولید کلمات کلیدی مناسب، بهبود سرعت بارگذاری صفحات و لینک‌سازی داخلی و خارجی کار می‌کنیم. تمامی کمپین‌های تبلیغاتی ما طوری طراحی شده‌اند که علاوه بر جذب ترافیک هدفمند، رتبه سایت رو در نتایج جستجو بهبود بدن.",
    category: "/service/3",
  },
  {
    question: "آیا تبلیغات دیجیتال شما فقط برای کسب‌وکارهای بزرگ هست؟",
    answer:
      "قطعاً نه! سرویس‌های تبلیغاتی سیریر مناسب کسب‌وکارهای کوچک و متوسط هم هست. ما خدماتی انعطاف‌پذیر ارائه می‌کنیم که با توجه به نیاز و بودجه هر کسب‌وکاری قابل تنظیم و اجراست.",
    category: "/service/3",
  },
  {
    question:
      "دوره‌های آموزشی سیریر در حوزه آینده‌پژوهی و انسان آینده چه مفاهیمی رو پوشش می‌ده؟",
    answer:
      "دوره‌های آموزشی ما به بررسی روندهای آینده، تکنولوژی‌های نوظهور، تحولات اجتماعی و اقتصادی و تغییرات در سبک زندگی انسان‌ها می‌پردازد. هدف این دوره‌ها اینه که شما رو برای دنیای فردا مجهز کنه و دیدی جامع نسبت به تحولات آینده به شما بده.",
    category: "/event/67c979af66c3cccfec2ac81c",
  },
  {
    question: "محتوای دوره‌های آموزشی چطور طراحی شده؟",
    answer:
      "تیم متخصص ما با تکیه بر سابقه ۲۳ ساله، دوره‌های آموزشی رو با محوریت کاربردی بودن، به‌روز بودن و تاکید بر تجربیات واقعی طراحی کرده. از کارگاه‌های تعاملی و مطالعات موردی گرفته تا جلسات نقد و بررسی روندها، همه رو می‌بینید.",
    category: "/event/67c979af66c3cccfec2ac81c",
  },
  {
    question: "چطور می‌تونم در دوره‌های آموزشی سیریر ثبت‌نام کنم؟",
    answer:
      "برای ثبت‌نام در دوره‌های آموزشی، کافیست به بخش دوره‌ها در سایت مراجعه کنید. با وارد کردن شماره موبایلتون، از طریق پیامک (SMS) تأیید ثبت‌نام انجام می‌شه و بعد از تکمیل اطلاعات، می‌تونید به محتوای آموزشی دسترسی پیدا کنید.",
    category: "/event/67c979af66c3cccfec2ac81c",
},
  {
    question: "آیا پس از دوره‌های آموزشی، گواهینامه یا مدرکی دریافت می‌کنم؟",
    answer:
      "بله! پس از اتمام دوره‌های آموزشی، مدرک معتبر از سیریر دریافت می‌کنید که می‌تونه به عنوان یک گواهی‌نامه ارزشمند در رزومه و حرفه شما مد نظر قرار بگیره.",
    category: "/event/67c979af66c3cccfec2ac81c",
  },
];
