import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SrviceInfo } from "../../pages/ServiceDetails";
import { Link } from "react-router-dom";

const BannerSlider = ({ data }) => {
  return (
    <div>
      <Swiper
        modules={[Autoplay]}
        speed={2500}
        allowTouchMove={false}
        breakpoints={{
          10: { slidesPerView: 2 },
          480: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
          1224: { slidesPerView: 5 },
        }}
        autoplay={{ delay: 0 }}
        loop
        className="sample-slider bg-[#142819] w-full px-1 py-3"
      >
        {SrviceInfo?.filter((item) => item.title !== "طراحی سایت و سئو")?.map(
          (item) => (
            <SwiperSlide className="p-2 ">
              <div className="text-[#ECFEF4] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap">
                {item.title}
              </div>
            </SwiperSlide>
          )
        )}
        <SwiperSlide className="p-2 ">
          <Link
            to={"/service/2"}
            className="text-[--primary] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap"
          >
            طراحی سایت و سئو
          </Link>
        </SwiperSlide>
        {SrviceInfo?.filter((item) => item.title !== "طراحی سایت و سئو")?.map(
          (item) => (
            <SwiperSlide className="p-2 ">
              <div className="text-[#ECFEF4] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap">
                {item.title}
              </div>
            </SwiperSlide>
          )
        )}
        <SwiperSlide className="p-2 ">
          <Link
            to={"/service/2"}
            className="text-[--primary] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap"
          >
            طراحی سایت و سئو
          </Link>
        </SwiperSlide>
        {SrviceInfo?.filter((item) => item.title !== "طراحی سایت و سئو")?.map(
          (item) => (
            <SwiperSlide className="p-2 ">
              <div className="text-[#ECFEF4] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap">
                {item.title}
              </div>
            </SwiperSlide>
          )
        )}
        <SwiperSlide className="p-2 ">
          <Link
            to={"/service/2"}
            className="text-[--primary] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap"
          >
            طراحی سایت و سئو
          </Link>
        </SwiperSlide>
        {SrviceInfo?.filter((item) => item.title !== "طراحی سایت و سئو")?.map(
          (item) => (
            <SwiperSlide className="p-2 ">
              <div className="text-[#ECFEF4] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap">
                {item.title}
              </div>
            </SwiperSlide>
          )
        )}
        <SwiperSlide className="p-2 ">
          <Link
            to={"/service/2"}
            className="text-[--primary] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap"
          >
            طراحی سایت و سئو
          </Link>
        </SwiperSlide>
        {SrviceInfo?.filter((item) => item.title !== "طراحی سایت و سئو")?.map(
          (item) => (
            <SwiperSlide className="p-2 ">
              <div className="text-[#ECFEF4] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap">
                {item.title}
              </div>
            </SwiperSlide>
          )
        )}
        <SwiperSlide className="p-2 ">
          <Link
            to={"/service/2"}
            className="text-[--primary] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap"
          >
            طراحی سایت و سئو
          </Link>
        </SwiperSlide>
        {SrviceInfo?.filter((item) => item.title !== "طراحی سایت و سئو")?.map(
          (item) => (
            <SwiperSlide className="p-2 ">
              <div className="text-[#ECFEF4] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap">
                {item.title}
              </div>
            </SwiperSlide>
          )
        )}
        <SwiperSlide className="p-2 ">
          <Link
            to={"/service/2"}
            className="text-[--primary] border-l border-white py-1 lg:py-2 text-[16px] lg:text-[24px] 2xl:text-[28px] flex gap-2 items-center justify-center whitespace-nowrap"
          >
            طراحی سایت و سئو
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default BannerSlider;
