import React, { useContext, useEffect, useRef, useState } from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import RateToStar from "../../utils/rateToStart";

const ProductCardAudio = ({ data, home, full }) => {
  const { name, category, price, sepcial_price, img, _id, rate, reviews } =
    data;
  const myRef = useRef(null);

  return (
    <div
      style={{ padding: "16px" }}
      className={`${
        full
          ? "col-12"
          : home
          ? "col-xl-3 col-lg-4 col-md-6 col-7"
          : "col-xl-4 col-lg-4 col-md-6 col-6"
      }   `}
    >
      <Reveal head>
        <div className="duration-200 hover:bg-yellow-200/40 hover:scale-105 rounded-[12px] overflow-hidden">
          <Link to={"/products/detail/" + _id}>
            <div className="product-o__wrap">
              <a
                className="aspect  aspect--square u-d-block"
                href="product-detail.html"
              >
                <img
                  className="aspect__img object-cover"
                  // src="/images/product/electronic/product3.jpg"
                  src={CDN_BASE_URL + img}
                  alt=""
                />
              </a>
            </div>

            <div className=" text-center">
              <h4 className="mt-2 text-[#292929] text-[16px] lg:text-[20px]">
                {name}
              </h4>
            </div>
          </Link>
        </div>
      </Reveal>
    </div>
  );
};

export default ProductCardAudio;
