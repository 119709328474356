import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link, useParams } from "react-router-dom";
import Reveal from "../components/motion/Reveal";
import Reviews from "../components/home/Reviews";
import { FaAngleLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { TEAM_INFO } from "./About";
import { Helmet } from "react-helmet";
import {
  FaEnvelope,
  FaInstagram,
  FaLinkedin,
  FaPhone,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa6";

const TeamInfo = () => {
  const { name } = useParams();
  const data = TEAM_INFO.find((item) => item.name === name);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.name}</title>
        <meta name="description" content={data?.meta_desc} />
        <meta name="og:description" content={data?.meta_desc} />
        <meta name="og:title" content={data?.name} />
        <meta name="og:image" content={`https://sirir.ir${data.img}`} />
      </Helmet>
      <Header />
      <div class="app-content bg-[#fafafa] pb-10 ">
        <div className="bg-slate-900 text-white">
          <div className="grid justify-items-center md:justify-items-start grid-cols-3 py-8 lg:py-24 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]">
            <div className="grid gap-8 col-span-3 lg:col-span-1">
              <div className="md:flex-row md:items-center justify-between mb-4 gap-4">
                <div className="flex flex-col mr-2 gap-3">
                  <div className="mb-4">
                    <h1 className="font-bold text-3xl md:text-5xl lg:text-5xl text-center md:text-right my-2">
                      {data.name}
                    </h1>
                    <p className="text-[16px] lg:text-[18px]  text-center md:text-right">
                      {data.role}
                    </p>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div className="flex-col md:flex-row lg:flex-row gap-4 w-full hidden md:flex">
                      <a
                        href={`mailto:${data.email}`}
                        className="bg-white p-3 px-12 border hover:bg-black text-black hover:text-white w-auto lg:text-sm xl:text-sm 2xl:text-lg flex justify-center items-center gap-2"
                      >
                        <p className="flex gap-2 items-center">
                          <FaEnvelope size={24} /> ایمیل
                        </p>
                      </a>
                      {data.whatsapp && (
                        <a
                          href={data.whatsapp}
                          target="_blank"
                          rel="noreferrer"
                          className="bg-white p-3 px-12 border hover:bg-black text-black hover:text-white w-auto lg:text-sm xl:text-sm 2xl:text-lg flex justify-center items-center gap-2"
                        >
                          <p className="flex gap-2 items-center">
                            <FaWhatsapp size={24} /> واتساپ
                          </p>
                        </a>
                      )}
                    </div>
                    <div>
                      <ul className="flex md:justify-start text-white gap-3 items-center justify-center">
                        {data.instagram && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={data.instagram}
                            >
                              <FaInstagram size={40} />
                            </a>
                          </li>
                        )}

                        {data.linkeidn && (
                          <li>
                            <a
                              href={data.linkeidn}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FaLinkedin size={40} />
                            </a>
                          </li>
                        )}

                        {data.telegram && (
                          <li>
                            <a
                              href={data.telegram}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FaTelegram size={40} />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-3 pt-4 lg:pt-10 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%] bg-[#fafafa] gap-10">
            <div className="grid col-span-2 grid-cols-3 gap-8 ">
              <div className="text-black col-span-3 md:col-span-2 lg:col-span-3">
                <div
                  className="detail_desc"
                  dangerouslySetInnerHTML={{ __html: data.desc }}
                />
                {data?.resume_file && (
                  <div className="w-full flex justify-center mt-5">
                    <Link
                      to={data?.resume_file}
                      target="_blank"
                      className="btn--e-primary py-2 px-6 text-[18px] "
                    >
                      مشاهده فایل رزومه
                    </Link>{" "}
                  </div>
                )}

                <div className="flex flex-col md:flex-row lg:flex-row gap-2 w-full md:hidden mt-3">
                  {data.email && (
                    <a
                      href={`mailto:${data.email}`}
                      target="_self"
                      rel="noopener noreferrer"
                      className="w-full p-3 border border-black bg-black text-white lg:text-sm xl:text-sm 2xl:text-sm lg:w-full flex justify-center items-center gap-2 hover:bg-white hover:text-black duration-200"
                    >
                      <FaEnvelope />
                      <p className="font-semibold">ایمیل</p>
                    </a>
                  )}
                  {data.whatsapp && (
                    <a
                      href={data.whatsapp}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-full p-3 border border-black bg-black text-white lg:text-sm xl:text-sm 2xl:text-sm lg:w-full flex justify-center items-center gap-2 hover:bg-white hover:text-black duration-200"
                    >
                      <FaWhatsapp />
                      <p className="font-semibold">واتساپ</p>
                    </a>
                  )}
                </div>
              </div>
              <div className="object-cover object-top lg:absolute lg:top-[30%] xl:top-[30%] 2xl:top-[25%] lg:left-20 xl:left-[6%] 2xl:left-[12%] flex items-center justify-center w-full lg:w-[30%] xl:w-[25%] 2xl:w-[20%] col-span-3 md:col-span-1 order-first md:order-last">
                <img src={data.img} alt={data.name} />
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-p-t-40 bg-[#fafafa]">
          <div class="section__intro u-s-m-b-30">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section__text-wrap">
                    <Reveal head>
                      <h2 class="text-[32px] lg:text-[40px] text-[#292929]">
                        دیگر اعضا
                      </h2>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section__content mb-16">
            <div class="container">
              <Swiper
                breakpoints={{
                  10: { slidesPerView: 2 },
                  620: { slidesPerView: 3 },
                  1024: { slidesPerView: 4 },
                  1254: { slidesPerView: 4 },
                }}
                modules={[Autoplay]}
                autoplay={{ delay: 4000 }}
                loop
                spaceBetween={10}
              >
                {TEAM_INFO.filter((item) => item.name !== name).map((item) => (
                  <SwiperSlide>
                    <div class="w-full">
                      <Reveal>
                        <Link to={"/team/" + item.name}>
                          <div class="team-member u-h-100">
                            <div class="team-member__wrap">
                              <div class="">
                                <img class=" " src={item.img} alt="" />
                              </div>
                            </div>
                            <div class=" bg-black p-3">
                              <h5 class="text-white text-[14px] lg:text-[18px]">
                                {item.name}
                              </h5>
                              <span class="text-white text-[12px] lg:text-[18px]">
                                {item.role}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </Reveal>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TeamInfo;
